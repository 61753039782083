/**
 *
 *
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * [Component] PageWrapper
 * --
 */
const PageWrapper = ({ children }) => {
  /* ===== State ===== */
  const navi = useNavigate();

  /* ===== Render ===== */
  return (
    <>
      <footer
        className="footer-main"
        style={{
          background: '#313131',
        }}
      >
        <div className="footer-top">
          <div className="container">
            {/* row */}
            <div className="row justify-content">
              <div className="col-lg-3 col-md-12">
                <div className="about-widget">
                  <div className="footer-logo">
                    <figure>
                      <a href="index.html">
                        <img
                          style={{ width: 160 }}
                          loading="lazy"
                          className="img-fluid"
                          src="/logo_png_white.png"
                          alt="medic"
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <h4 style={{ color: '#fff' }}>Quick Links</h4>
                <hr style={{ borderColor: '#c8c8c8' }} />
                <p style={{ color: '#c8c8c8' }}>
                  {[
                    {
                      title: 'About Saha',
                      path: '/history-culture',
                    },
                    {
                      title: 'Saha District Bulletin',
                      path: '/saha-district-bulletin',
                    },
                    {
                      title: 'Lifestyle & Transportation',
                      path: '/lifestyle-transportation',
                    },
                    {
                      title: 'KIC Progress',
                      path: '/kic-progress',
                    },
                    {
                      title: 'SahaEdushere',
                      path: '/saha-edu',
                    },
                  ].map((item) => (
                    <>
                      <a key={item.path} onClick={() => navi(item.path)}>
                        {item.title}
                      </a>
                      <br />
                    </>
                  ))}
                </p>
                <br />
                <h4 style={{ color: '#fff' }}>Saha District Office</h4>
                <hr style={{ borderColor: '#c8c8c8' }} />
                <p>
                  <a>
                    49328 ) 12 Nakdongdae-ro 398beon-gil, Saha District, Busan,
                    South Korea{' '}
                  </a>
                </p>
              </div>

              <div className="col-lg-4 col-md-12">
                <h4 style={{ color: '#fff' }}>Connect with Us</h4>
                <hr style={{ borderColor: '#c8c8c8' }} />

                <p>
                  <a>admin@newsaha.net</a>
                  <br />
                  <a>Tel: (+82)51-220-4000</a>
                  <br />
                  <a>Fax: (+82)51-220-4269</a>
                  <br />
                  <a>Business Hours: Mon-Fri 9:00 AM - 6:00 PM</a>

                  <br />
                </p>
              </div>
            </div>
            {/* row */}
          </div>
        </div>
        <div
          className="footer-bottom"
          style={{
            background: '#3a3a3a',
          }}
        >
          <div
            className="container clearfix"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="copyright-text" style={{ flex: 1 }}>
              {/* <p>
                &copy; Copyright 2021. Designed &amp; Developed by{' '}
                <a href="https://themefisher.com/">Themefisher</a>
              </p> */}
              <p>Copyright © 2023 Saha District Office. All rights reserved.</p>
            </div>
            <div style={{ flex: 1 }}>
              <ul className="footer-bottom-link">
                {[
                  {
                    icon: 'fa-linkedin',
                    path: 'https://linkedin.com/company/newsaha',
                  },
                  {
                    icon: 'fa-twitter',
                    path: 'https://twitter.com/newsaha2030',
                  },
                  {
                    icon: 'fa-facebook-f',
                    path: '',
                  },
                  {
                    icon: 'fa-instagram',
                    path: '',
                  },
                  // {
                  //   icon: 'fa-google-plus-g',
                  //   path: '',
                  // },
                ].map((item) => (
                  <React.Fragment key={`social-${item.icon}`}>
                    <button
                      className="btn btn-light"
                      style={{
                        borderColor: '#c3c3c3',
                        background: 'none',
                        width: 40,
                        height: 35,
                      }}
                    >
                      <a
                        href={item.path}
                        target="_blank"
                        aria-label="facebook"
                        style={{ color: '#fff' }}
                      >
                        <i className={`fab ${item.icon}`}></i>
                      </a>
                    </button>{' '}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PageWrapper;
