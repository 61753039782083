import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <section 
      className="page-title text-center" 
      style={{ 
        backgroundImage: `url("/resources/NotFound.jpg")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div>
        <h1>Page Not Found</h1>
        <button onClick={goToHome} className="btn btn-success btn-lg" style={{ marginTop: '20px' }}>
          Go to Home
        </button>
      </div>
    </section>
  );
};

export default NotFound;
