import React from 'react';
import { getCookie } from 'utils';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'; // Choose the desired Font Awesome icon
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Section = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start; // Align items at their start (top) position
  margin: 0;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column; // Stack components on smaller screens
  }
`;


const FAQContainer = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-family: 'Your-Question-Font', sans-serif;
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;

  h3 {
    font-size: 24px;
    color: #333;
    display: flex;
    align-items: center; /* Center vertically */
  }
`;

const IconWrapper = styled.div`
  margin-right: 10px; /* Adjust spacing as needed */
  font-size: 1.5rem; /* Adjust the icon size as needed */
`;

const AccordionHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FAQItem = styled.div`
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-family: 'Your-Answer-Font', sans-serif;
`;

const FAQQuestion = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: 20px;

  background-color: #11ab87;
  &:hover {
    background-color: #09976d;
  }

  h4 {
    font-size: 18px;
    color: #fff;
    margin: 0;
    font-family: 'Your-Question-Font', sans-serif;
  }
`;

const FAQAnswer = styled.div`
  padding: 0 15px 15px;
  display: none;
  font-family: 'Your-Answer-Font', sans-serif;
`;

const FAQ = () => {
  const language = getCookie('country');
  const isKorean = language === 'KR';

  const faqItems = [
    {
      question: isKorean
        ? '사하구의 비전은 무엇인가요?'
        : 'What is the vision of the Saha District?',
      answer: isKorean
        ? '사하구의 비전은 \'앙상블 도시\'라는 명칭에서도 알 수 있듯이, 자연의 평온함과 도시생활의 활기찬 리듬 사이의 조화를 창출하는 것입니다. 사하구는 숲, 바다, 산, 강이 어우러진 자연 경관의 평온함과 시장과 항구 등이 활기찬 도시 생활의 에너지를 결합하여, 기쁨, 충족감, 그리고 생활의 균형을 추구하는 탁월한 삶의 경험을 제공하려는 목표를 가지고 있습니다. 이처럼 사하구는 단순한 행정구역을 넘어, 인생에서 가장 아름다운 순간들의 앙상블을 창조하는 곳이라는 생각으로 그 지역을 개발하고 있습니다.'
        : 'The vision of the Saha District, known as \'The Ensemble City,\' is to create a harmonious balance between the tranquility of nature and the vibrant rhythm of urban life. The district aims to provide an exceptional living experience that combines the best of both worlds – the peaceful serenity of natural landscapes, such as parks, seascapes, mountains, and rivers, and the exciting dynamism of city life with bustling markets and active ports. In its essence, the Saha District is dedicated to creating a lifestyle where joy, fulfillment, and balance are at the core, making it more than just a district, but an ensemble of life\'s finest moments.',
    },
    {
      question: isKorean
        ? '사하구의 최신 뉴스와 업데이트 소식을 어떻게 알 수 있나요?'
        : 'How can I stay informed about the latest news and updates from the Saha District?',
      answer: isKorean
        ? '사하구의 모든 최신 뉴스, 공지사항 및 업데이트를 최신 상태로 유지하려면 몇 가지 옵션을 사용할 수 있습니다. 사하구 공식 링크드인 및 트위터 페이지를 팔로우하면 즉각적인 뉴스와 업데이트를 확인할 수 있습니다. 또는 정기적으로 공지사항과 보도자료를 게시하는 사하구 공식 웹사이트를 방문하실 수도 있습니다. 사하구의 개발 프로젝트부터 문화 행사 등 다양한 주제를 다루는 사하구 뉴스레터를 구독하면 심층적인 정보와 인사이트를 얻을 수 있습니다. 사하구 소식과 최신 정보를 놓치지 마세요!'
        : 'To stay up-to-date with all the latest news, announcements, and updates from Saha District, there are several options at your disposal. For immediate news and updates, you can follow the official Saha District LinkedIn and Twitter pages. Alternatively, you can visit the Saha District\'s official website which regularly posts announcements and press releases. For in-depth information and insights, subscribe to the Saha District newsletters which cover a wide range of topics, from the district\'s development projects to cultural events and much more. Stay connected with Saha District and never miss an update!',
    },
    {
      question: isKorean
        ? '사하구의 주요 명소와 편의시설에는 어떤 것이 있나요?'
        : 'What are some of the key attractions and amenities in the Saha District?',
      answer: isKorean
        ? '사하구는 대한민국 부산의 활기차고 역동적인 지역으로 전통과 문화, 현대적인 편의시설이 잘 어우러진 곳으로 유명합니다. 화려한 색채의 주택과 예술적 설치물로 유명한 감천문화마을부터 조류 관찰로 유명한 을숙도, 상쾌한 해수욕을 즐길 수 있는 다대포해수욕장까지 사하구에는 탐험하고 즐길 거리가 많습니다. 또한 사하구는 교육과 비즈니스의 중심지로 여러 학교와 대학교, 분주한 산업단지가 자리하고 있습니다. 사하구에는 관광, 비즈니스, 거주 목적 등 다양한 관심사와 니즈를 충족시킬 수 있는 다양한 시설이 마련되어 있습니다. 사하구와 다양한 명소 및 편의시설에 대한 자세한 내용은 공식 웹사이트를 방문하거나 사하구 공식 소셜 미디어 채널을 팔로우하여 최신 소식을 확인하세요!'
        : 'The Saha District is a vibrant and dynamic region of Busan, South Korea, known for its rich blend of tradition, culture, and modern amenities. From the iconic Gamcheon Culture Village, known for its brightly colored houses and artistic installations, to Eulsukdo Island, renowned for its birdwatching opportunities, and Dadaepo Beach, a popular destination for a refreshing sea bath, there\'s much to explore and enjoy in Saha District. The district is also a hub for education and business, housing several schools, universities, and a bustling industrial complex. Whether you\'re visiting for tourism, business, or considering making Saha your home, the district has a diverse array of offerings that cater to a wide range of interests and needs. To learn more about Saha District and its many attractions and amenities, visit the official website or follow the official Saha District social media channels for the latest updates.',
    },
  ];

  const toggleAnswer = (index) => {
    const answer = document.getElementById(`answer${index}`);
    answer.style.display = answer.style.display === 'block' ? 'none' : 'block';
  };

  return (
    <Section>
    <FAQContainer>
      <SectionTitle>
        <h3>
          <IconWrapper>
            <FontAwesomeIcon icon={faQuestionCircle} /> {/* Add the Font Awesome icon here */}
          </IconWrapper>
          FAQ
        </h3>
      </SectionTitle>
      <AccordionHolder>
        {faqItems.map((item, index) => (
          <FAQItem key={index}>
            <FAQQuestion onClick={() => toggleAnswer(index)}>
              <h4>{item.question}</h4>
              <IconWrapper>
                <FontAwesomeIcon icon={faChevronDown} />
              </IconWrapper>
            </FAQQuestion>
            <FAQAnswer id={`answer${index}`}>{item.answer}</FAQAnswer>
          </FAQItem>
        ))}
      </AccordionHolder>
    </FAQContainer>
    </Section>
  );
};

export default FAQ;