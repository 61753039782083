import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Styled components
const Section = styled.section`
  margin: 0;
  padding: 0;
`;

const LeftContainer = styled.div`
  background: #FFFFFF;
  padding: 50px 0;
  display: flex; // Enable flexbox
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
  height: 100%; // Take full height of the parent

`;


const RightContainer = styled.div`
  background: linear-gradient(135deg, #11998e, #38ef7d);
  padding: 40px 15px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); // Updated shadow
`;

const Container = styled.div`
  display: flex;
  background: #FFFFFF;
  flex-direction: row;
  align-items: center;
`;

const ContentBlock = styled.div`
  padding: 20px;
`;

const Heading = styled.h2`
  font-size: 28px;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
`;

const LearnMoreButton = styled.button`
background-color: #11ab87;
color: #ffffff;
border: none;
border-radius: 5px;
padding: 10px;
cursor: pointer;
font-size: 16px;
margin-top: 10px;
width: 50%;
transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }
`;


const ImageWrapper = styled.div`
  cursor: pointer;
  border-radius: 20px;
  max-width: 500px; // Set max-width for the image wrapper
  width: 100%; // Take full width
  display: flex; // Enable flexbox
  justify-content: center; // Center image horizontally
  align-items: center; // Center image vertically
`;


// AboutSection Component
const AboutSection = ({ language }) => {
  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate('/history-culture');
  };
  
  return (
    <Section className="service-overview section">
      <div className="container-fluid">
        <Container className="row">
          <LeftContainer className="col-lg-9 col-md-12">
            <div className="container">
              <div className="row">
                <div className="col-xl-2 col-lg-12 col-md-12" />
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-xs-12">
                <ImageWrapper onClick={handleImageClick}>
                <img
                  src="resources/about_image.jpeg"
                  width="100%"
                  style={{ borderRadius: 20, maxWidth: '500px' }}
                  alt="ABOUT SAHA"
                />
              </ImageWrapper>
                </div>
                <div className="col-xl-6 col-lg-8 col-md-7 col-sm-7 col-xs-12">
                  <ContentBlock>
                    <Heading>ABOUT SAHA</Heading>
                    <br></br>
                    <Paragraph>
                      {language !== 'KR' ? (
                        <>
                          Welcome to the Saha District, a dynamic region in Busan, South Korea. Our district is a unique blend of history, culture, and modern amenities. With a deep-rooted tradition and an eye toward the future, Saha District offers a rich tapestry of experiences for residents and visitors alike...
                        </>
                      ) : (
                        <>
                          대한민국 부산의 역동적인 지역인 사하구에 오신 것을 환영합니다. 우리 구는 역사, 문화, 현대적인 편의시설이 독특하게 조화를 이루고 있습니다. 뿌리 깊은 전통과 미래를 향한 비전으로 사하구는 주민과 방문객 모두에게 풍성한 경험을 제공합니다...
                        </>
                      )}
                    </Paragraph>
                    <LearnMoreButton onClick={() => navigate('/history-culture')}>
                      Learn more
                    </LearnMoreButton>
                  </ContentBlock>
                </div>
              </div>
            </div>
          </LeftContainer>

          <RightContainer className="col-lg-3 col-md-12">
            <h1>NEW SAHA</h1>
          </RightContainer>
        </Container>
      </div>
    </Section>
  );
};

export default AboutSection;
