/**
 *
 *
 */

import React from 'react';
import { PageTitle } from 'components';

/**
 * [Component] DepartmentOverview
 * --
 */
const En = () => {
  /* ===== State ===== */
  const members = [
    {
      position: 'Manager',
      name: 'Kim Hyun-seok',
      contact: '+82-51-220-4310',
      email: 'khs9460@korea.kr',
      responsibilities: '',
    },
    {
      position: 'Supervisor',
      name: 'Yoon Sang-jin',
      contact: '+82-51-220-4313',
      email: 'ysjin0708@korea.kr',
      responsibilities: 'Head of the Future Initiatives Team',
    },
    {
      position: 'Officer',
      name: 'Yu Hyun-seok',
      contact: '+82-51-220-4314',
      email: 'kalinhand78@korea.kr',
      responsibilities:
        'Reviewing initiatives and specific directives received from the mayor',
    },
    {
      position: 'Officer',
      name: 'Hong Yong-wook',
      contact: '+82-51-220-4312',
      email: 'kora2116@korea.kr',
      responsibilities: 'Long-term Saha development plans',
    },
    {
      position: 'Officer',
      name: 'Jeong Min-gyu',
      contact: '+82-51-220-4315',
      email: 'jmg89@korea.kr',
      responsibilities: 'The development of Dadaepo coast ',
    },
    {
      position: 'Officer',
      name: 'Kwon Da-hae',
      contact: '+82-51-220-4316',
      email: 'gooneoda@korea.kr',
      responsibilities: 'Tourism development project',
    },
    {
      position: 'Officer',
      name: 'Jeong Ye-rin',
      contact: '+82-51-220-4311',
      email: 'rin13561@korea.kr',
      responsibilities: 'Secretarial and accounting duties',
    },
    {
      position: 'Supervisor',
      name: 'Lee Keun-yeong',
      contact: '+82-51-220-5023',
      email: 'icib123@korea.kr',
      responsibilities: 'Head of the Business Expansion Team',
    },
    {
      position: 'Officer',
      name: 'Kim Yeon-jeong',
      contact: '+82-51-220-5022',
      email: 'kyj01178@korea.kr',
      responsibilities:
        'Managing industrial complexes and enhancing business regulations',
    },
    {
      position: 'Officer',
      name: 'Kim Ji-yeon',
      contact: '+82-51-220-5024',
      email: 'kjeey@korea.kr',
      responsibilities:
        'Renewable energy generation projects, hydrogen fuel supply facilities',
    },
    {
      position: 'Officer',
      name: 'Jeong Seol-ah',
      contact: '+82-51-220-5026',
      email: 'reuna16@korea.kr',
      responsibilities:
        'Knowledge Industry Centers, renewable energy generation project',
    },
    {
      position: 'Officer',
      name: 'Jeon Ga-yeon',
      contact: '+82-51-220-5025',
      email: 'jgy1106@korea.kr',
      responsibilities: 'Facilitating industrial-academia cooperation',
    },
  ];

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title="Department Overview"
        breadcrumb={['Department', 'Department Overview']}
      />

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>Introduction</h2>
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                  eius optio repellendus quasi nisi vitae laboriosam explicabo
                  eligendi sapiente in. Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Beatae, dolorum esse tempora id architecto
                  laboriosam.
                </p> */}
                <p>
                  Welcome to the page of the Strategic Industry Department. Our
                  dedicated team diligently works towards systematically shaping
                  the long-term development blueprint for the Saha district and
                  facilitating an array of regional development projects. We are
                  engaged in devising innovative solutions to streamline
                  regulations, with the goal of enticing business investments
                  and fostering an environment conducive to enterprise growth.
                  Our primary mission is to lay a robust foundation for the
                  future growth of Saha, as we strive towards constructing a
                  brighter future and fostering a reinvigorated Saha. Thank you
                  for your interest and support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* === Left === */}
            <div className="col-lg-7 col-md-12 mb-lg-0">
              <div className="content-block">
                <h2>What We Do Clinical And</h2>
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                  eius optio repellendus quasi nisi vitae laboriosam explicabo
                  eligendi sapiente in. Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Beatae, dolorum esse tempora id architecto
                  laboriosam.
                </p> */}
                <ul>
                  {[
                    'Formulating holistic long-term development blueprints',
                    `Leading the execution of Saha's forward-thinking development programs`,
                    `Pioneering the expansion of tourism via strategic development initiatives`,
                    `Streamlining regulatory protocols in relation to Knowledge Industry Centers and commercial enterprises`,
                    `Administering the suite of renewable energy projects and nurturing emergent growth arenas, inclusive of avant-garde initiatives`,
                    `Supervising the amalgamation of academic pursuits with industrial enterprises, along with other accountabilities`,
                  ].map((item) => (
                    <li>
                      <i className="fas fa-caret-right"></i>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* === Right === */}
            <div
              className="col-lg-5 col-md-12 mb-lg-0"
              style={{ background: '#fff', padding: 30, paddingBottom: 0 }}
            >
              <div className="content-block">
                <h2 style={{ fontSize: '2.05em' }}>Contact Information</h2>

                <ul>
                  <li>[Strategic Industry Department]</li>
                  <li>- Email: admin@newsaha.net</li>
                  <li>- Phone number: 051-220-4311</li>
                  <li>- Fax: 051-220-4319</li>
                </ul>
              </div>
            </div>
            {/* === Right === */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="content-block">
            <h2>
              Organizational
              <br />
              Chart
            </h2>
          </div>
          {/* Row */}
          <div
            style={{
              margin: '15px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                background: '#339e0d',
                padding: 15,
                width: '100%',
                maxWidth: 660,
                height: 100,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                border: '2px solid #287d0a',
              }}
            >
              <h4>Strategic Industry Department</h4>
            </div>
          </div>
          {/* Row */}
          {/* Row */}
          <div
            style={{
              margin: '15px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: 320,
                margin: '0 10px',
              }}
            >
              <div
                style={{
                  background: '#fff',
                  padding: 15,
                  width: '100%',
                  height: 100,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#287d0a',
                  border: '2px solid #339e0d',
                }}
              >
                <h4>Future Initiatives Team</h4>
              </div>
            </div>

            <div
              style={{
                width: '100%',
                maxWidth: 320,
                margin: '0 10px',
              }}
            >
              <div
                style={{
                  background: '#fff',
                  padding: 15,
                  width: '100%',
                  height: 100,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#287d0a',
                  border: '2px solid #339e0d',
                }}
              >
                <h4>Business Expansion Team</h4>
              </div>
            </div>
          </div>
          {/* Row */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="content-block">
            <h2>
              Members<small>({members.length})</small>
            </h2>
          </div>
          {/* Row */}
          <div className="row">
            {/* <h6>구성원</h6> */}
            {/* === 구성원 === */}
            {members.map((item) => (
              <div className="col-lg-4 col-md-6" style={{ padding: 10 }}>
                <div
                  className="team-person text-left "
                  style={{
                    padding: '20px 25px',
                    height: 300,
                    background: '#fff',
                    border: '1px solid #f1f1f1',
                    boxShadow: '1px 1px 5px -2px rgba(0,0,0,0.3)',
                  }}
                >
                  <div
                    style={{
                      borderRadius: '100%',
                      width: 55,
                      height: 55,
                      background: '#f1f1f1',
                      marginBottom: 15,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#dbdbdb',
                    }}
                  >
                    <h5>{item.name[0]}</h5>
                  </div>
                  <h5>
                    {item.name} <small>/ {item.position}</small>
                  </h5>
                  <hr style={{ margin: '10px 0' }} />
                  <p style={{ margin: 0 }}>
                    <b>Contact</b>: {item.contact}
                  </p>
                  <p style={{ margin: 0 }}>
                    <b>Email</b>: {item.email}
                  </p>
                  {item.responsibilities && (
                    <p style={{ margin: 0 }}>
                      <b>Responsibilities</b>: {item.responsibilities}
                    </p>
                  )}
                </div>
              </div>
            ))}
            {/* === 구성원 === */}
          </div>
          {/* Row */}
        </div>
      </section>
    </>
  );
};

export default En;
