/**
 *
 *
 */

import React from 'react';
import { getCookie } from 'utils';
import Ko from './DistrictHousing.ko';
import En from './DistrictHousing.en';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] PageSample
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie('country');

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang['district_housing']}
        breadcrumb={[lang['saha_life'], lang['district_housing']]}
        backgroundImage={'resources/saha_life/1_District_Housing_pic1.JPG'}
        backgroundPosition={'center 65%'}
      />
      {language !== 'KR' ? <En /> : <Ko />}
    </>
  );
};

export default PageIndex;
