/**
 *
 *
 */

import React from 'react';

/**
 * [Component] DivisionOverview
 * --
 */
const En = ({ data, onChange, sendEmail }) => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>Business & Investment Inquiry</h2>
                <p className="align-justify">
                  Saha District is a hub for knowledge-based industries,
                  continuously fostering growth, innovation, and prosperity.
                  Whether you are considering relocating your business to our
                  Knowledge Industry Centers, launching a new venture in Saha,
                  or seeking advice and information about the incentives we
                  offer, we are here to guide you. Reach out to us using the
                  form below or by sending an email directly. We're committed to
                  responding promptly to all inquiries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-area pl-0 pl-lg-5">
                <div className="section-title">
                  <h3>Inquiry form</h3>
                </div>
                <form
                  name="contact_form"
                  className="default-form contact-form"
                  action="!#"
                  method="post"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Name"
                          placeholder="Your Name"
                          required=""
                          value={data.name}
                          onChange={(e) => onChange('name', e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          name="Email"
                          placeholder="Your Email"
                          required=""
                          value={data.email}
                          onChange={(e) => onChange('email', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Phone"
                          placeholder="Your Phone number"
                          required=""
                          value={data.phone}
                          onChange={(e) => onChange('phone', e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="subject"
                          defaultValue={'부동산'}
                          onChange={(e) => onChange('type', e.target.value)}
                        >
                          <option value="부동산">real estate</option>
                          <option value="기술">tech startups</option>
                          <option value="관광">tourism</option>
                          <option value="지식산업센터">KICs</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="affiliation"
                          placeholder="Affiliation"
                          required=""
                          value={data.company}
                          onChange={(e) => onChange('company', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="form_message"
                          placeholder="Content"
                          required=""
                          value={data.content}
                          onChange={(e) => onChange('content', e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-group text-center">
                        <button
                          type="button"
                          className="btn-style-one"
                          onClick={() => sendEmail()}
                        >
                          Submit now
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <img
                src="resources/strategic_business_division/1_DivisionOverview3.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-9 col-md-12">
              {/* = 주소 = */}
              <>
                <div className="content-block">
                  <h2>Direct Contact Information</h2>
                </div>
                <p style={{ fontSize: '1.2em' }}>
                  Please feel free to send direct inquiries regarding investment
                  or business opportunities in Saha District to{' '}
                  <a href="#">admin@newsaha.net</a>. Our team is eager to
                  provide you with all necessary information and assistance.
                </p>
              </>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <img
                src="resources/support/support_2_2.jpeg"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-7 col-md-12">
              {/* = 주소 = */}
              <>
                <div className="content-block">
                  <h2>Visiting Saha District for Business </h2>
                </div>
                <p style={{ fontSize: '1.2em' }}>
                  If you are visiting Saha District for business and require
                  guidance regarding accommodations, corporate meeting spaces,
                  or any other necessities, please do not hesitate to contact
                  us. We are dedicated to making your business visit as
                  productive and pleasant as possible.
                </p>
              </>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              {/* = 주소 = */}
              <>
                <div className="content-block">
                  <h2>Unleash Your Business Potential in Saha District</h2>
                </div>
                <p style={{ fontSize: '1.2em' }}>
                  Investing or starting a business in Saha District is not only
                  a strategic decision for growth but also an opportunity to
                  join a thriving, innovative community. We appreciate your
                  interest and look forward to discussing potential
                  opportunities with you.
                </p>
              </>
            </div>

            <div className="col-lg-5 col-md-12">
              <img
                src="resources/support/support_2_3.jpg"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default En;
