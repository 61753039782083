/**
 *
 *
 */

import React from 'react';

/**
 * [Component] 구청장 인사말
 * --
 */
const Ko = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section
        className="service-overview section "
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          zIndex: 10,
          // background: '#f1f1f1',
          color: '#fff',
        }}
      >
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-1 col-md-12" /> */}

            <div
              className="col-lg-8 col-md-12"
              style={{
                display: 'flex',
                // alignItems: 'center',
                alignItems: 'flex-end',
              }}
            >
              <div className="content-block" style={{ width: '100%' }}>
                <br />
                <br />
                <h1
                  style={{
                    border: 'none',
                    color: '#2a2a2a',
                    width: '100%',
                    fontSize: '3.35em',
                    // lineHeight: 1,
                  }}
                >
                  이갑준
                  <hr
                    style={{ margin: '13px 0 -10px 0', borderColor: '#9a9a9a' }}
                  />
                  <small
                    style={{
                      fontWeight: '600',
                      fontSize: '0.5em',
                      color: '#5a5a5a',
                    }}
                  >
                    부산광역시 사하구청장
                  </small>
                </h1>
                <br />
                <p className="align-justify">
                  문화적인 풍부함이 흐르는 토양에서 피어나는 혁신, 그리고
                  현대성과 전통이 공존하는 매력적인 사하구에 오신 것을 진심으로
                  환영합니다.
                  <br />
                  <br />
                  사하구청장으로서, 저는 기운 넘치고 활기찬 사하구, 끊임없이
                  노력하는 우리 시민들, 그리고 모두가 함께 번영하고 포용하는
                  지역사회를 만드는 데에 헌신하는 것에 대해 깊은 자부심을
                  느낍니다. 우리 사하구의 이 여정에 여러분이 함께 동행하고
                  공감해 주시길 바랍니다.
                  <br />
                  <br />
                </p>
                {/* <h4>Mayor of Saha District, Busan</h4> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-12"
              style={{
                width: '100%',
                height: '100%',
                minHeight: 500,
                background: `url("resources/discover_saha/00p.png")`,
                backgroundPosition: 'center 100%',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
          </div>
        </div>
      </section>

      {/*  */}
      <section
        className="service-overview section bg-gray"
        style={{
          boxShadow: '-1px 0px 15px -1px rgba(0,0,0, 0.1)',
          zIndex: 11,
        }}
      >
        <div className="container">
          <div className="content-block">
            <h2>
              <small>Dear Global Friends and Visitors,</small>
              <br />
              전세계 방문객 여러분,
            </h2>
          </div>
          {/* === Row === */}
          <div className="row">
            <div className="col-lg-6">
              <img
                src="resources/discover_saha/1_Mayors_greetings_pic3.jpg"
                width="100%"
                style={{ borderRadius: 20, border: '0px solid #dadada' }}
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <p className="align-justify">
                대한민국 부산의 서부권의 낙동강이 바다와 맞닿은 곳에 자리 잡은
                반짝이는 보석 같은 사하구를 방문하게 된 것을 기쁘게 생각합니다.
                <br />
                <br />
                사하구 구청장으로서 풍부한 역사, 역동적인 산업 성장, 경외감을
                불러일으키는 생태적 경이로움으로 가득한 이 놀라운 구를 안내하게
                되어 매우 영광스럽게 생각합니다.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          {/* === Row === */}

          {/* === Row === */}
          <div className="row">
            <div className="col-lg-6">
              <p className="align-justify">
                우리 구의 뿌리는 신평-장림공단이 부산의 활기찬 제조업의
                선두주자로 부상했던 1980년대와 1990년대의 산업 전성기로 거슬러
                올라갑니다. 도금, 염색, 피혁, 자동차 부품 등 다양한 분야에
                특화된 이 공단은 부산의 성장 스토리를 이끌었습니다.
                <br />
                <br />
                오늘날 전통 산업이 진화하고 우리 주변의 세계가 변화함에 따라
                사하구도 변화하고 있습니다. 우리는 산업 유산을 활용하여
                급성장하는 산업에서 새로운 기회를 창출하고, 사하구를 전 세계의
                혁신 기업과 획기적인 스타트업의 매력적인 목적지로 만들고
                있습니다.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="resources/discover_saha/1_Mayors_greetings_pic4.JPG"
                width="100%"
                style={{ borderRadius: 20, border: '0px solid #dadada' }}
                alt=""
              />
            </div>
          </div>
          {/* === Row === */}
        </div>
      </section>

      <section
        className="service-overview section "
        style={{
          background: `url("resources/discover_saha/1_Mayors_greetings_pic5.jpg")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center 90%',
          padding: '155px 0',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            background: 'rgba(0,0,0,0.55)',
            height: '100%',
            top: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <h1 style={{ color: '#fff', textAlign: 'center' }}>
              Joint Declaration of Cooperation
            </h1>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div className="row">
            {/* = 1 = */}
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: '15px 25px' }}
            >
              <div className="content-block">
                <h3 style={{ color: '#4a4a4a' }}>
                  낙동강 시대를 품다: 공동 협력 선언
                </h3>
                <br />
              </div>
              <div className="">
                <img
                  src="resources/discover_saha/1_Mayors_greetings_pic6.jpg"
                  width="98%"
                  style={{
                    borderRadius: 20,
                    border: '0px solid #dadada',
                    maxHeight: 355,
                  }}
                  alt=""
                />
              </div>
              <br />
              <p className="align-justify">
                이러한 비전을 바탕으로 사하구를 '낙동강 테크노밸리'로
                탈바꿈시키고자 합니다. 이 비전은 신평-장림 지역에 13개의
                지식산업센터(KIC) 건립을 통해 실현되고 있습니다. 이 혁신의
                허브는 첨단 기술을 육성하고 선구적인 스타트업을 육성하며 부산의
                미래를 추진하겠다는 우리의 확고한 의지를 보여주고 있습니다.
              </p>
            </div>
            {/* = 1 = */}

            {/* = 2 = */}
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: '15px 25px' }}
            >
              <div className="content-block">
                <h3 style={{ color: '#4a4a4a' }}>
                  사하구: 자연과 도시의 매력이 만나는 곳
                </h3>
                <br />
              </div>
              <div className="">
                <img
                  src="resources/discover_saha/1_Mayors_greetings_pic7.jpg"
                  width="98%"
                  style={{
                    borderRadius: 20,
                    border: '0px solid #dadada',
                    maxHeight: 355,
                  }}
                  alt=""
                />
              </div>
              <br />
              <p className="align-justify">
                하지만 사하구는 단순한 산업기반의 도시만이 아닙니다. 사하구는
                도시 생활과 고요한 풍경이 조화를 이루는 천혜의 자연을 간직한
                곳이기도 합니다. 을숙도, 아름다운 다대포해수욕장 등 생태계의
                보고가 있는 이곳은 관광, 휴식, 레저 활동의 무한한 잠재력을
                지니고 있습니다. 우리는 이러한 자산을 보존하고 지속 가능한
                개발을 통해 주민과 관광객 모두에게 최고 수준의 시설을 제공하기
                위해 최선을 다하고 있습니다.
              </p>
            </div>
            {/* = 2 = */}

            {/* = 3 = */}
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: '15px 25px' }}
            >
              <div className="content-block">
                <h3 style={{ color: '#4a4a4a' }}>
                  잠재력을 발휘하다: 사하구의 연결성 미래
                </h3>
                <br />
              </div>
              <div className="">
                <img
                  src="resources/discover_saha/1_Mayors_greetings_pic8.png"
                  width="98%"
                  style={{
                    borderRadius: 20,
                    border: '0px solid #dadada',
                    maxHeight: 355,
                  }}
                  alt=""
                />
              </div>
              <br />
              <p className="align-justify">
                미래는 사하구에 활기찬 기회로 가득합니다. 2029년 가덕도신공항이
                개항하고 2030년 부산형 급행철도인 BuTX가 완공되면 우리 구의
                접근성이 크게 향상될 것입니다. 이러한 인프라 사업들은 사하구를
                국내외 교통의 요충지로 자리매김하게 할 것이며, 우리 지역
                주민들에게 무궁무진한 기회를 가져다 줄 것입니다.
              </p>
            </div>
            {/* = 3 = */}

            {/* = 4 = */}
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: '15px 25px' }}
            >
              <div className="content-block">
                <h3 style={{ color: '#4a4a4a' }}>
                  활기찬 지역사회를 위한 사하구의 비전
                </h3>
                <br />
              </div>
              <div className="">
                <img
                  src="resources/discover_saha/1_Mayors_greetings_pic9.jpg"
                  width="98%"
                  style={{
                    borderRadius: 20,
                    border: '0px solid #dadada',
                    maxHeight: 355,
                  }}
                  alt=""
                />
              </div>
              <br />
              <p className="align-justify">
                사하구청장으로서 저는 사하구를 삶의 터전으로 삼는 모든 분들의
                삶의 질을 높이는 데 최선을 다하고 있습니다. 사하의 생활 인프라를
                개선하기 위해 끊임없이 노력하고 있습니다. 여기에는 다목적
                문화센터 건립, 산업단지 내 실내체육관 건립, 경관을 감상할 수
                있는 산책로 조성 등이 포함됩니다. 특히 낙동강 산책로는 낙동강의
                아름다운 일몰을 감상하며 우리 지역의 자연을 만끽할 수 있는
                매력적인 명소로 조성될 예정입니다.
              </p>
            </div>
            {/* = 4 = */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section  bg-gray">
        <div
          className="container"
          style={{ maxWidth: 700, textAlign: 'center' }}
        >
          <p className="align-justify">
            사하구에 관심을 가져 주셔서 감사합니다. 거주민, 사업주, 방문객 모두
            사하구가 제공하는 풍부한 기회를 살펴보시고 발견해 보시기 바랍니다.
            사하구 발전의 새로운 장을 함께 써 나갑시다.
          </p>
          <br />
          {/* <h4>Warm regards,</h4> */}

          <h5>감사합니다.</h5>

          <br />
          <br />
          <h3 style={{ position: 'relative' }}>
            <br />
            사하구청장,&nbsp;&nbsp;&nbsp;&nbsp;이갑준&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>
              <img
                src="resources/discover_saha/01p.png"
                width={380}
                style={{
                  borderRadius: 20,
                  border: '0px solid #dadada',
                  position: 'absolute',
                  right: -10,
                  bottom: -53,
                }}
                alt=""
              />
            </small>
          </h3>
        </div>
      </section>
    </>
  );
};

export default Ko;
