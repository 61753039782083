/**
 *
 *
 */

import React from 'react';

/**
 * [Component] InnovationTech
 * --
 */
const Ko = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-8 col-md-12">
              <div className="content-block">
                <h2>
                  사하구의 혁신 산업
                  <br />
                  <small>"사하구는 변화와 혁신 지원합니다"</small>
                </h2>
                <p className="align-justify">
                  사하구 중심부에서 제조업부터 지식 기반 및 ICT 산업에
                  이르기까지 핵심 산업의 지형을 끊임없이 재편하고 있습니다.
                  스마트 기술, 혁신적인 관행, 전략적 인프라를 통합하여 미래로의
                  도약을 지원하는 방법을 살펴보세요.
                </p>
              </div>
            </div>
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12" style={{ textAlign: 'center' }}>
              <div
                style={{
                  width: '95%',
                  height: '93%',
                  margin: '0 auto',
                  backgroundSize: 'cover',
                  border: '1px solid #dadada',
                  borderRadius: 20,
                  backgroundImage: `url("resources/strategic_business_division/2_InnovationTech_pic2.jpg")`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* === #1 === */}
            {/* 본문 */}
            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h2>
                  <small>제조업, 지식기반산업, 첨단산업:</small>
                  <br />
                  지식산업센터의 중추
                </h2>
                <p className="align-justify">
                  사하구는 대한민국 공장 제조업의 주요 거점이었습니다. 수년에
                  걸쳐 점차 지식 기반 산업과 첨단 산업으로 그 중심이 옮겨가면서
                  이 분야의 중추적인 중심지로 발전해 왔습니다. 지식산업센터의
                  중추인 사하구의 공장 제조업은 이러한 산업의 성장을 견인하는 데
                  중요한 역할을 하고 있습니다. 지식기반 및 첨단 산업의 발전과
                  번영에 필요한 인프라, 숙련된 인력, 혁신 정신을 제공합니다.
                </p>
              </div>
            </div>
            {/* 본문 */}

            {/* 이미지 */}
            <div className="col-lg-5 col-md-12" style={{ textAlign: 'center' }}>
              <div
                style={{
                  width: '95%',
                  height: '93%',
                  minHeight: 250,
                  margin: '0 auto',
                  backgroundSize: 'cover',
                  border: '1px solid #dadada',
                  borderRadius: 20,
                  backgroundImage: `url("resources/strategic_business_division/2_InnovationTech_pic3.jpg")`,
                }}
              ></div>
            </div>
            {/* 이미지 */}
            <div className="col-12">
              <br />
              <br />
              <br />
            </div>
            {/* === #1 === */}

            {/* === #2 === */}

            {/* 본문 */}
            {[
              [
                '제조업의 혁신',
                '#1',
                '제조업은 지식산업센터의 주요 입주 산업 중 하나입니다. 원재료를 가공하여 새로운 제품으로 생산하는 산업으로 경제 성장의 중추적인 역할을 담당하고 있습니다. 지식산업센터는 제조업의 성장을 지원함으로써 지역 경제 활성화에 기여하고 있습니다.',
              ],
              [
                '지식 및 ICT 산업: 통합적 접근 방식',
                '#2',
                '지식산업센터는 지식기반 및 ICT 산업과도 밀접한 연관성을 가지고 있습니다. 지식산업은 지식과 정보의 활용을 기반으로 하는 반면, ICT 산업은 컴퓨터와 통신 기술에 뿌리를 두고 있습니다. 지식산업센터는 이러한 산업에 도움이 되는 공간을 제공함으로써 이들 산업의 성장과 발전을 지원합니다.',
              ],
              [
                '지역 경제 및 일자리 창출 촉진',
                '#3',
                '지식산업센터는 제조업, 지식기반산업, ICT 산업의 성장을 촉진함으로써 지역 경제 활성화에 크게 기여하고 있습니다. 일자리 창출과 경제 성장에 필수적인 역할을 하며 지역 경제의 초석으로 자리매김하고 있습니다. 지식산업센터에는 "산업집적활성화 및 공장설립에 관한 법률"에 명시된 제조업, 지식산업, ICT, R&D 등 다양한 업종이 입주할 수 있습니다.',
              ],
              [
                '지식산업센터의 장점',
                '#4',
                '지식산업센터는 제조업, 지식산업, ICT, R&D 등 다양한 업종이 입주할 수 있는 다기능 복합단지입니다. 입주 기업을 위한 다양한 지원 시설과 함께 재정적, 금융적 혜택을 제공합니다. 일자리 창출과 경제 성장에 큰 역할을 하며 지역 경제의 중추적인 역할을 하고 있습니다.',
              ],
              [
                '낙동강 테크노밸리 조성',
                '#5',
                '낙동강 테크노밸리 조성은 첨단산업단지로의 변신을 목표로 산업구조 전환과 고부가가치 산업 유치를 구체화합니다. 다각적인 산업 공간 육성을 통해 첨단 산업이 주도하는 성장 거점으로 거듭나기를 기대하고 있습니다. 낙동강 테크노밸리의 진화는 혁신에 대한 사하구의 의지를 보여주는 증거이며, 산업과 주민 모두에게 풍요로운 미래를 약속합니다.',
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                key={`sbt-${index}`}
                style={{ marginBottom: 25 }}
              >
                <div
                  style={{
                    background: '#fff',
                    padding: 25,
                    height: '100%',
                  }}
                >
                  <div className="content-block">
                    <h4 style={{ color: '#333' }}>
                      {/* <small>{item[1]}</small> */}
                      {item[1]}
                      <br />
                      {item[0]}
                    </h4>
                    <hr />
                    <p className="align-justify">{item[2]}</p>
                  </div>
                </div>
              </div>
            ))}
            {/* 본문 */}

            {/* === #2 === */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-4 col-md-12">
              <div className="content-block">
                <h2>
                  <small>지식 산업 센터는 다양한 혜택을 제공합니다</small>
                </h2>
              </div>
            </div>
            <div className="col-1"></div>
            {/* 이미지 */}
            <div className="col-lg-7 col-md-12">
              <h6>
                ✔️ 쾌적한 근무환경 및 업무 효율성 제고
                <hr />
                ✔️ 첨단업종 클러스터 형성으로 기술·정보교류 활성화 및 생산성
                향상
                <hr />
                ✔️ 다양한 커뮤니티시설·휴게시설·문화공간 등 지원시설 보유
                <hr />
                ✔️ 정부의 적극적인 지원
                <hr />
                ✔️ 지역 일자리 창출 및 경제 성장에의 기여
              </h6>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>수소산업 : 수소 에너지로 미래를 연료로 </h2>
              </div>
              <p className="align-justify">
                사하구에서는 지구에서 가장 깨끗하고 효율적인 에너지원 중 하나인
                수소의 힘을 활용하고 있습니다. 미래 지향적인 에너지원인 수소는
                탄소 중립의 핵심 수단이며 다양한 혁신 산업에 높은 잠재력을
                가지고 있습니다. 우리는 수소 경제를 선도하고 지역을 수소 기반
                도시로 변화시키기 위해 최선을 다하고 있습니다
              </p>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            {/* 내용 */}
            {[
              [
                '수소 경제에 대한 선제적 접근',
                '사하구는 수소산업을 둘러싼 국내외 환경에 대응해 수소경제가 우리 지역의 새로운 성장동력이 될 수 있도록 선제적으로 대응하고 있습니다. 탄탄한 수소 생태계를 조성하고 생산 및 공급 인프라를 확충하는 데 주력하고 있습니다.',
              ],
              [
                '수소 생태계 사업 구축',
                '우리는 수소 생태계를 활성화 위해 수소 기업 경쟁력 강화, 수소 기술 개발 육성, 수소 제품 품질 인증 및 검사 지원, 수소 산업 인재 양성 등을 중점적으로 추진하고 있습니다. 지역내 연료전지, 액체수소, 암모니아를 기반으로 한 핵심 기술 개발에 집중하며 수소 시장 선점을 위한 준비를 하고 있습니다.',
              ],
              [
                '수소 에너지의 저변 확대',
                '사하구는 "시민 친화적 수소 경제" 구현을 위해 수소 기반 친환경 도시 및 친환경 산업단지 조성에 박차를 가하고 있습니다. 수소를 주택, 교통 및 기타 도시의 주요 에너지원으로 사용한다는 비전을 가지고 수소차와 연료전지 사용을 확대하는 데 힘을 쏟고 있습니다.',
              ],
              [
                '시민 친화적인 친환경 도시 건설',
                '사하구 전역의 주택에 수소연료전지 보급을 확대하고 스마트팜 등 다양한 분야에서 수소에너지 활용도를 높이기 위해 노력하고 있습니다. 우리의 궁극적인 목표는 수소 에너지로 구동되는 시민 친화적 친환경 도시 사하를 만드는 것입니다.',
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                key={`asdwm${index}`}
                style={{ marginBottom: 25 }}
              >
                <div
                  style={{
                    background: '#fff',
                    padding: 25,
                    height: '100%',
                  }}
                >
                  <h4 style={{ color: '#333' }}>{item[0]}</h4>
                  <hr />
                  <p className="align-justify">{item[1]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div style={{ maxWidth: 700, textAlign: 'center', margin: '0 auto' }}>
            <h4>
              우리와 함께 어떻게 수소 에너지 솔루션을 산업과 인프라에 통합하고
              미래 지향적인 에너지원의 잠재력을 수용하고 있는지 함께 알아보세요.
            </h4>
          </div>
        </div>
      </section>

      {/*  */}
      {/* <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">

            <div className="col-lg-8 col-md-12">
              <div className="content-block">
                <h2>
                  <small>로봇 공학으로 산업 재정의</small>
                  <br />
                  Changer사하구의 로봇 산업: 게임 체인저
                </h2>
              </div>
              <p className="align-justify">
                사하구에서는 로봇 공학을 다양한 산업에 통합하는 데 앞장서고
                있습니다. 이 혁신적인 기술을 활용하여 프로세스를 재정의하고
                효율성을 높이며 새로운 가능성을 열어가고 있습니다.
              </p>
            </div>

            <div className="col-lg-4 col-md-12" style={{ textAlign: 'center' }}>
              <div
                style={{
                  width: '95%',
                  height: '93%',
                  minHeight: 200,
                  margin: '0 auto',
                  backgroundSize: 'cover',
                  border: '1px solid #dadada',
                  borderRadius: 20,
                  backgroundImage: `url("resources/strategic_business_division/2_InnovationTech_pic5.jpg")`,
                }}
              ></div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            {[
              [
                '로봇공학의 힘',
                '로봇 산업은 인간의 작업을 보조하거나 대체하도록 설계된 기계인 로봇의 개발 및 생산과 관련이 있습니다. 제조, 의료, 서비스, 농업 등 다양한 분야에서 활용되고 있는 이 강력한 도구는 지속적인 기술 발전에 힘입어 빠르게 성장하고 있습니다.',
              ],
              [
                '자동화의 역할',
                '로봇공학의 중요한 측면인 자동화는 기계와 장비를 사용하여 사람의 작업을 자동화하는 것을 말합니다. 생산성 향상, 품질 개선, 인건비 절감 등의 잠재적 이점을 지닌 자동화는 다양한 산업 분야에서 활용되고 있으며, 향후 그 사용은 기하급수적으로 증가할 것으로 예상됩니다.',
              ],
              [
                '지식 산업 센터: 로봇공학의 허브',
                '사하구에 위치한 지식산업센터는 로봇 산업 및 자동화 관련 기술과 밀접하게 연결되어 있습니다. 로봇을 개발 및 생산하고, 자동화 시스템을 운영하며, 로봇과 자동화 시스템을 업무에 적용하는 기업들을 위한 육성 환경을 제공합니다. 이러한 새로 건설되는 지식산업센터들은  로봇 및 자동화 관련 기술의 발전과 확산을 촉진하는 데 중요한 역할을 할 것입니다.',
              ],
              [
                '기술의 다양성',
                '로봇공학의 기술은 놀라울 정도로 다양하고 활용도가 높습니다. 조립이나 검사 작업을 위한 제조 공정의 로봇, 제품 운반이나 분류를 위한 창고의 로봇, 고객 서비스 로봇, 농작물 수확이나 가축 관리를 위한 농업용 로봇 등이 그 예입니다. 이러한 기술은 생산성 향상, 품질 향상, 인건비 절감에 크게 기여하고 있습니다.',
              ],
              [
                '미래를 향한 비전',
                '사하구에서는 지식산업센터에 로봇 기술을 활용할 수 있도록 최선을 다해 지원하고 있습니다. 노후화된 신평·장림산업단지를 혁신 기술의 허브로 탈바꿈시켜 산업을 재정의하고 효율성을 개선하며 새로운 가능성을 모색하는 데 로봇공학의 힘을 활용하고자 합니다. 미래를 향한 여정에 함께해 주세요.',
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                key={`asdwm${index}`}
                style={{ marginBottom: 25 }}
              >
                <div
                  style={{
                    background: '#fff',
                    padding: 25,
                    height: '100%',
                  }}
                >
                  <h4 style={{ color: '#333' }}>{item[0]}</h4>
                  <hr />
                  <p className="align-justify">{item[1]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-5 col-md-12" style={{ textAlign: 'center' }}>
              <div
                style={{
                  width: '95%',
                  height: '93%',
                  minHeight: 170,
                  margin: '0 auto',
                  backgroundSize: 'cover',
                  border: '1px solid #dadada',
                  borderRadius: 20,
                  backgroundImage: `url("resources/strategic_business_division/2_InnovationTech_pic6.jpg")`,
                }}
              ></div>
            </div>
            {/* 내용 */}
            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h2>사하구와 번성하는 수산식품산업</h2>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            {/* 내용 */}
            {[
              [
                '감천항: 부산 수산식품 산업의 중심',
                '부산의 대표적인 항구 중 하나인 감천항은 부산 수산물 70%의 중요한 통로 역할을 하고 있습니다. 연간 수산물 유통량이 100만 톤에 육박하는 부산 최대의 어항으로 자리매김하고 있습니다. 또한 감천항은 수산물 가공공장, 유통센터, 시장 등이 밀집해 있어 부산 수산식품 산업의 진원지로서 입지를 굳건히 하고 있습니다.',
              ],
              [
                '사하구의 중요한 역할',
                '감천항이 있는 사하구는 부산의 탄탄한 수산물 산업을 상징하는 지역으로 잘 알려져 있습니다. 수산물 가공 및 유통의 중심 허브인 사하구의 수산물 산업은 지역 경제에 활력을 불어넣고 주민과 지역 사회를 위한 수많은 일자리를 창출하고 있습니다.',
              ],
              [
                '부산 발전의 원동력',
                '사하구 감천항에 기반을 둔 수산식품 산업은 부산의 수산물 산업을 발전시키는 강력한 원동력입니다. 부산 내 수산물 유통을 촉진하고 부산의 수산물 가공 및 유통 허브로서 중요한 위치를 차지하고 있습니다.',
              ],
              [
                '수산식품특화단지',
                '사하구 신평-장림 산업단지 내에 자리한 수산식품특화단지는 2015년에 조성되기 시작했습니다. 15만 5천 평방미터의 면적에 수산물 가공 공장, 연구소, 유통센터 등이 입주해 있습니다. 이 특화 단지의 개발은 특히 수산물 가공 및 유통 부문의 일자리 창출을 강화하고 지역 경제 활동에 활력을 불어넣을 것으로 기대됩니다.',
              ],
              [
                '수산식품특화단지에서 혁신을 선도하다',
                '수산식품특화단지에는 다양한 수산식품을 생산하는 가공 공장이 입주해 있습니다. 또한 단지내의 연구소들은 수산물 품질 향상과 새로운 수산물 제품 혁신에 전념하고 있습니다.',
              ],
              [
                '수산식품발전의 밝은 미래',
                '사하구의 수산식품특화단지는 지역 일자리 창출과 경제 활성화의 잠재력으로 가득 찬 밝은 미래를 보여줍니다. 새로운 고용의 길을 열고 지역 수산물 가공 및 유통 산업에 활력을 불어넣을 준비가 되어 있습니다. 궁극적으로 수산물 특화 단지는 지역 수산식품 산업을 발전시키고 지역 경제를 활성화하는 데 중추적인 역할을 할 것입니다.',
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                key={`asdwm${index}`}
                style={{ marginBottom: 25 }}
              >
                <div
                  style={{
                    background: '#fff',
                    padding: 25,
                    height: '100%',
                  }}
                >
                  <h4 style={{ color: '#333' }}>{item[0]}</h4>
                  <hr />
                  <p className="align-justify">{item[1]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-9 col-md-12">
              <div className="content-block">
                <h2>사하구의 미래지향적 전략</h2>
                <br />
              </div>
            </div>

            {/* 카드 */}
            {[
              [
                '인공지능, 로보틱스 및 자동화 활용',
                `사하구는 인공지능(AI), 로봇공학, 자동화와 같은 첨단 기술을 활용하여 첨단 제조업의 선구자가 되는 것을 목표로 합니다. 사하구는 AI를 도입하여 제조 공정을 간소화하여 효율을 높이고 오류를 줄일 계획입니다. 로봇 공학은 반복적인 작업을 자동화하여 시간을 절약하고 운영 비용을 절감하는 데 중요한 역할을 할 것으로 예상됩니다. 이러한 미래지향적인 전략으로 사하구는 혁신의 선두주자로 자리매김하며 제조업의 미래를 만들어갈 준비가 되어 있습니다.`,
              ],
              [
                `IoT와 빅데이터를 통한 물류 혁신`,
                `사하구는 사물인터넷(IoT)과 빅데이터 분석을 구현하여 공급망 관리를 혁신할 계획입니다. 사하구는 IoT를 활용하여 서로 통신하는 상호 연결된 장치 네트워크를 구축하여 물류 프로세스를 간소화하는 것을 목표로 합니다. 또한 빅데이터 분석을 통해 방대한 양의 정보를 분석하여 배송 시간을 최적화하고 낭비를 최소화하며 정보에 입각한 의사결정을 내릴 수 있도록 할 것입니다. 물류에 대한 이러한 총체적인 접근 방식은 효율성을 높일 뿐만 아니라 지역 내에서 제공되는 전반적인 서비스 품질을 향상시킵니다.`,
              ],
              [
                `바이오 및 첨단 의료 기술을 통한 헬스케어 혁신 촉진`,
                `사하구는 바이오 및 첨단 의료 기술의 잠재력을 수용하여 의료 혁신의 경계를 넓히기 위해 노력하고 있습니다. 구는 이러한 기술을 활용해 질병 진단과 환자 치료를 개선하고 혁신적인 치료법 개발을 촉진할 계획입니다. 첨단 의료 기술의 사용은 개별 환자에게 맞춤화된 치료를 제공하는 개인 맞춤형 의료로 이어질 수 있으며, 잠재적으로 주민들의 의료 결과와 삶의 질을 개선할 수 있습니다.`,
              ],
              [
                `AI와 가상 현실을 통한 리테일 산업의 혁신`,
                `사하구는 AI와 가상현실(VR)을 활용한 리테일 산업 혁명을 구상하고 있습니다. AI 기술은 소비자 행동을 분석하여 개인화된 쇼핑 경험을 제공하고 고객 만족도를 높일 수 있습니다. 반면 VR은 고객이 구매하기 전에 가상으로 제품을 체험해 볼 수 있도록 함으로써 쇼핑 경험을 혁신할 수 있습니다. 이러한 획기적인 기술은 리테일 경험을 개선할 뿐만 아니라 보안을 강화하고 사기를 줄이는 데에도 기여합니다.`,
              ],
              [
                `AI와 머신러닝을 통한 지속 가능한 미래`,
                `사하구는 에너지 효율적인 환경을 조성하기 위해 AI와 머신러닝의 잠재력을 활용하는 등 지속가능성을 위해 최선을 다하고 있습니다. 사하구는 광범위한 지속가능성 목표에 맞춰 에너지 소비 패턴을 개선하고 탄소 배출량을 크게 줄이는 것을 목표로 하고 있습니다. 이러한 이니셔티브를 통해 사하구는 주민들을 위해 더 친환경적이고 지속 가능한 미래를 향한 길을 열어가고 있습니다.`,
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                style={{ marginBottom: 18 }}
                key={`askldj${index}`}
              >
                <div
                  className="align-justify"
                  style={{
                    background: '#fff',
                    boxShadow: '1px 1px 15px -1px rgba(0,0,0,0.1)',
                    height: '100%',
                    padding: 25,
                    // borderRadius: 13,
                  }}
                >
                  <h4>{item[0]}</h4>
                  <hr />
                  <p>{item[1]}</p>
                </div>
              </div>
            ))}
            {/* 카드 */}
            {/* 카드 */}
            <div className="col-lg-6 col-md-6" style={{ marginBottom: 18 }}>
              <div
                className="align-justify"
                style={{
                  background: '#fff',
                  boxShadow: '0px 0px 10px -1px rgba(0,0,0,0.05)',
                  height: '100%',
                  padding: 20,
                  // borderRadius: 13,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  color: '#fff',
                  backgroundColor: '#11ab87',
                }}
              >
                <h5>
                  <b>
                    사하구는 이러한 미래지향적 기술을 수용하고 통합함으로써
                    미래를 준비할 뿐만 아니라 적극적으로 미래를 만들어가고
                    있으며, 모든 주민들에게 활기차고 지속 가능하며 기술적으로
                    진보된 미래를 보장하고 있습니다.
                  </b>
                </h5>
              </div>
            </div>
            {/* 카드 */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ko;
