/**
 *
 *
 */

import React from 'react';
import { getCookie } from 'utils';
import Ko from './DepartmentOverview.ko';
import En from './DepartmentOverview.en';

/**
 * [Component] DepartmentOverview
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie('language');

  /* ===== Render ===== */
  // return <En />;
  if (language === '') return <En />;
  else return <Ko />;
};

export default PageIndex;
