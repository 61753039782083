import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const imgs = [
  { 
    src: 'main_pic_1.png', 
    url: '/saha-vision', 
    title: 'Saha\'s Vision', 
    description: 'Experience the harmony of nature and urban life in the Saha District' 
  },
  { 
    src: 'main_pic_2.jpeg', 
    url: '/development-plan', 
    title: 'Development Plan 2030', 
    description: 'Explore Saha District\'s ambitious Development Plan for the future'
  },
  { 
    src: 'main_pic_3.jpeg', 
    url: '/innovation-tech', 
    title: 'Pioneering Innovation in the Saha District', 
    description: 'Explore Saha\'s cutting-edge innovation for a brighter future.' 
  },
  { 
    src: 'main_pic_4.jpeg', 
    url: '/district-housing', 
    title: 'Vibrant Living in the Saha District', 
    description: 'Discover the innovative housing developments and community-enhancing initiatives' 
  },
  // Add more items as necessary
];
const length = imgs.length;

const MainSlider = () => {
  const navigate = useNavigate();
  const [slider, setSlider] = useState(1);

  const handleChangeSliderCount = (type = 'prev') => {
    if (type === 'prev') {
      setSlider(slider > 1 ? slider - 1 : length);
    } else {
      setSlider(slider < length ? slider + 1 : 1);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSlider(slider < length ? slider + 1 : 1);
    }, 5000);
    return () => clearInterval(timer);
  }, [slider]);


    // Responsive styles
    const mobileStyle = {
      navigationSize: window.innerWidth < 768 ? '30px' : '40px', // Adjust navigation button size based on screen width
      titleFontSize: window.innerWidth < 768 ? '7vw' : '3vw', // Responsive font size for title
      descriptionFontSize: window.innerWidth < 768 ? '4vw' : '2vw' // Responsive font size for description
    };

      // Adjust these values to change the size of your slider
  const sliderHeight = '400px'; // Smaller height
  const sliderWidth = '100%'; // Example: 80% of the viewport width


  

  return (
    <div style={{ background: '#f0f0f5', padding: '20px', borderRadius: '50px' }}>
      <div id="carouselExampleAutoplaying" className="carousel slide" style={{ maxWidth: sliderWidth, margin: 'auto' }}>
        <div className="carousel-inner" style={{ height: sliderHeight }}>
          {imgs.map((item, index) => (
            slider === index + 1 && (
              <div
                key={`mainslider-${index}`}
                className="carousel-item active"
                style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url("resources/home/${item.src}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',
                transition: 'all 0.5s ease',
                borderRadius: '50px'               
              }}
              onClick={() => navigate(item.url)}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background: 'rgba(0,0,0,0.40)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50px'
                }}
              >
                <div className="content style text-center">
                  <h1 style={{ fontSize: mobileStyle.titleFontSize, color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.8)' }}>
                    {item.title}
                  </h1>
                  <p style={{ color: '#d1d1d1', fontSize: mobileStyle.descriptionFontSize, textShadow: '2px 2px 4px rgba(0,0,0,0.8)' }}>
                    {item.description}
                    <br />
                    {`${index + 1} / ${length}`}
                  </p>
                </div>
              </div>
            </div>
          )
        ))}
      </div>

      {/* Slider Navigation Bar */}
      <div className="slider-navigation-bar" style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
          gap: '15px'
        }}>
        <button 
          onClick={() => handleChangeSliderCount('prev')}
          className="navigation-arrow"
          style={{
            border: 'none',
            background: '#fff',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            borderRadius: '50%',
            width: mobileStyle.navigationSize,
            height: mobileStyle.navigationSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span style={{
          fontSize: mobileStyle.descriptionFontSize,
          color: 'black',
          backgroundColor: '#fff',
          padding: '5px 10px',
          borderRadius: '10px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
        }}>
          {`${slider}/${length}`} <strong>{imgs[slider - 1].title}</strong>
        </span>
        <button 
          onClick={() => handleChangeSliderCount('next')}
          className="navigation-arrow"
          style={{
            border: 'none',
            background: '#fff',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            borderRadius: '50%',
            width: mobileStyle.navigationSize,
            height: mobileStyle.navigationSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
    </div>
  );
};

export default MainSlider;
