/**
 *
 *
 */

import React from 'react';

/**
 * [Component] Loading
 * --
 */
const Loading = ({ visible, position }) => {
  return (
    visible && (
      <>
        <div
          style={{
            position,
            zIndex: position === 'fixed' ? 999 : 99,
            width: '100%',
            background: 'rgba(255,255,255,0.85)',
            height: '100%',
            display: 'flex',
            top: 0,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <div class="spinner-border" role="status"></div>
            <h5>
              <span class="visually-hidden">Loading...</span>
            </h5>
          </div>
        </div>
      </>
    )
  );
};

Loading.defaultProps = {
  visible: false,
  position: 'fixed',
};
export default Loading;
