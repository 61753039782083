/**
 *
 *
 */

import React from 'react';
import { getCookie } from 'utils';
import Ko from './KICOverview.ko';
import En from './KICOverview.en';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] PageSample
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie('country');
  const list = [
    {
      title: '1. 펜타플렉스 1차',
      title_en: '1. PENTA PLEX 1',
      comment: `신평동 493번지에 위치하며, 건물 연면적은 86,308.87㎡입니다. 공장 404호실, 근생 29호실이 들어서며 1,527명이 고용될 것으로 예상됩니다.`,
      comment_en: `Situated at Sinpyeong-dong 493, the Gross Floor Area (GFA) of the building is 86,308.87㎡. It will house 404 factory units and 29 neighborhood units, with an anticipated employment of 1,527 people. `,
      address: `신평동 493번지`,
      address_en: `Situated at Sinpyeong-dong 493`,
      image: '1_KIC_Overview_pic1-1.png',
      // image: '1_KICOverview_pic2.png',
    },
    {
      title: '2. 스마트 T타워',
      title_en: '2. Smart T Tower',
      comment: `신평동 532번지에 위치하며, 건물 연면적은 112,292.33㎡입니다. 이 건물에는 공장 624호실과  36개의 지원 호실이 들어서며, 1,986명의 고용 창출이 예상됩니다.`,
      comment_en: `Located at Sinpyeong-dong 532, the GFA of the building is 112,292.33㎡. The building will accommodate 624 factory units and 36 support units, leading to an anticipated employment generation of 1,986 people.`,
      address: `신평동 532번지`,
      address_en: `Located at Sinpyeong-dong 532`,
      image: '1_KICOverview_pic3.jpeg',
    },
    {
      title: '3. 점프타워',
      title_en: '3. Jump Tower',
      comment: `신평동 648-2번지 일원, 연면적 24,207.88㎡ 규모. 이 지식산업센터는 428개의 일자리를 창출할 것으로 예상됩니다.`,
      comment_en: `Set at Sinpyeong-dong 648-2, with a GFA of 24,207.88㎡. This KIC is expected to create 428 jobs. `,
      address: `신평동 648-2번지`,
      address_en: `Set at Sinpyeong-dong 648-2`,
      image: '1_KICOverview_pic4.jpeg',
    },
    {
      title: '4. 리드원',
      title_en: '4. Lead One',
      comment: `신평동 370-6번지에 위치하며, 단지 연면적은 119,309.25㎡입니다. 2,111개의 일자리를 창출할 것으로 예상됩니다.`,
      comment_en: `Located at Sinpyeong-dong 370-6, the GFA of the complex is 119,309.25㎡. It is projected to create 2,111 jobs.`,
      address: `신평동 370-6번지`,
      address_en: `Located at Sinpyeong-dong 370-6`,
      image: '1_KICOverview_pic5.jpeg',
    },
    {
      title: '5. SK V1(U1)',
      title_en: '5. SK V1(U1)',
      comment: `신평동 520번지에 위치한 이 단지의 연면적은 165,648.66㎡입니다. 이곳에서 창출될 것으로 예상되는 일자리는 2,930개입니다.`,
      comment_en: `Located at Sinpyeong-dong 520, this complex has a GFA of 165,648.66㎡. The anticipated job creation here is 2,930.`,
      address: `신평동 520번지`,
      address_en: `Located at Sinpyeong-dong 520`,
      image: '1_KICOverview_pic6.jpeg',
    },
    {
      title: '6. 펜타플렉스 2차',
      title_en: '6. PENTA PLEX 2',
      comment: `신평동 546번지에 위치하며, 연면적 96,396.73㎡ 규모입니다. 이 건물은 1,705개의 일자리를 창출할 것으로 예상됩니다.`,
      comment_en: `Situated at Sinpyeong-dong 546, with a GFA of 96,396.73㎡. This building is anticipated to create 1,705 jobs. `,
      address: `신평동 546번지`,
      address_en: `Situated at Sinpyeong-dong 546`,
      image: '1_KICOverview_pic6-1.png',
      // image: '1_KICOverview_pic7.jpeg',
    },
    {
      title: '7. 점프타워2차',
      title_en: '7. Jump Tower 2',
      comment: `신평동 489번지에 위치하며, 연면적은 68,946.65㎡입니다. 이 건물로 예상되는 일자리 창출은 1,220명입니다.`,
      comment_en: `Positioned at Sinpyeong-dong 489, with a GFA of 68,946.65㎡. Job creation expected from this KIC is 1,220. `,
      address: `신평동 489번지`,
      address_en: `Positioned at Sinpyeong-dong 489`,
      image: '1_KICOverview_pic77.png',
    },
    {
      title: '8. 스카이 더 테라스',
      title_en: '8. Sky the Terrace',
      comment: `장림동 343-1번지에 위치하며, 연면적은 23,951.75㎡입니다. 이 건물은  424개의 일자리를 창출할 것으로 예상됩니다.`,
      comment_en: `Found at Jangnim-dong 343-1, with a GFA of 23,951.75㎡. This KIC is expected to create 424 jobs. `,
      address: `장림동 343-1번지`,
      address_en: `Found at Jangnim-dong 343-1`,
      image: '1_KICOverview_pic88.png',
    },
    {
      title: '9. 신평동 지식산업단지 건축 예정',
      title_en: '9. Upcoming KIC in Sinpyeong-dong',
      comment: `신평동 648-1번지에 위치하며, 연면적은 37,846.54㎡입니다. 669개의 일자리를 창출할 것으로 예상됩니다.`,
      comment_en: `Located at Sinpyeong-dong 648-1, with a GFA of 37,846.54㎡. It is projected to create 669 jobs. `,
      address: `신평동 648-1번지`,
      address_en: `Located at Sinpyeong-dong 648-1`,
      image: '1_KICOverview_pic99.png',
    },
    {
      title: '10. 장림동 지식산업단지 건축 예정',
      title_en: '10. Upcoming KIC in Jangnim-dong',
      comment: `장림동 1034번지에 위치하며, 건물 연면적은 44,332.85㎡입니다. 이곳은 784개의 일자리를 창출할 것으로 예상됩니다.`,
      comment_en: `Positioned at Jangnim-dong 1034, the GFA of the building is 44,332.85㎡. It is expected to create 784 jobs. `,
      address: `장림동 1034번지`,
      address_en: `Positioned at Jangnim-dong 1034`,
      image: '1_KICOverview_pic1010.png',
    },
    {
      title: '11. 장림동 지식산업단지 건축예정',
      title_en: '11. Upcoming KIC in Jangnim-dong',
      comment: `장림동 488번지, 연면적 93,818.26㎡에 위치. 이 건물에서 예상되는 일자리 창출은 1,660명입니다.`,
      comment_en: `Situated at Jangnim-dong 488, with a GFA of 93,818.26㎡. The anticipated job creation from this KIC is 1,660. `,
      address: `장림동 488번지`,
      address_en: `Situated at Jangnim-dong 488`,
      image: '1_KICOverview_pic1111.png',
    },
    {
      title: '12. 신평동 지식산업단지 건축 예정',
      title_en: '12. Upcoming KIC in Sinpyeong-dong',
      comment: `신평동 370-106번지에 위치하며, 건물 연면적은 125,267.04㎡입니다. 이곳의 예상 일자리 창출은 2,220명입니다.`,
      comment_en: `Located at Sinpyeong-dong 370-106, the GFA of the building is 125,267.04㎡. The anticipated job creation here is 2,220.`,
      address: `신평동 370-106번지`,
      address_en: `Located at Sinpyeong-dong 370-106`,
      image: '1_KICOverview_pic1212.png',
    },
    {
      title: '13. 신평동 지식산업단지 건축 예정',
      title_en: '13. Upcoming KIC in Sinpyeong-dong',
      comment: `신평동 645-2번지에 위치하며, 연면적은 70,378.95㎡입니다. 이곳은 1,245개의 일자리를 창출할 것으로 예상됩니다.`,
      comment_en: `Positioned at Sinpyeong-dong 645-2, with a GFA of 70,378.95㎡. This KIC is expected to generate 1,245 jobs. `,
      address: `신평동 645-2번지`,
      address_en: `Positioned at Sinpyeong-dong 645-2`,
      image: '1_KICOverview_pic1313.png',
    },
    {
      title: '14. 장림동 지식산업단지 건축 예정',
      title_en: '14. Upcoming KIC in Jangnim-dong',
      comment: `장림동에 위치한 이 지식산업단지는 연면적 99,100.37㎡, 지상 20층 규모로 2023년 9월 20일에 건축 허가가 되었습니다. 이 단지는 최신 기술과 시설을 갖춘 현대적인 비즈니스 환경을 제공할 것으로 기대됩니다.`,
      comment_en: `The upcoming Knowledge Industry Center in Jangnim-dong is planned to have a Gross Floor Area (GFA) of 99,100.37㎡ and will consist of 20 above-ground floors. The construction permit was granted on September 20, 2023. This complex is anticipated to offer a modern business environment equipped with the latest technology and facilities.`,
      address: `장림동 492-3번지`,
      address_en: `Located at 351, Dadae-ro, Saha-gu, Busan`,
      image: '1_KICOverview_pic1414.png',
    },
  ];

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang['kic_overview']}
        breadcrumb={[lang['kics'], lang['kic_overview']]}
        backgroundImage={'resources/KIC/1_KIC Overview_pic1.png'}
      />
      {language !== 'KR' ? <En list={list} /> : <Ko list={list} />}
    </>
  );
};

export default PageIndex;
