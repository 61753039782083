/**
 *
 *
 */

import React from 'react';

const styles = {
  cardWrapper: {
    padding: 3,
  },
  cardStyle: {
    padding: 10,
    boxShadow: '1px 1px 5px -1px rgba(0,0,0,0.1)',
    minHeight: 87,
    backgroundColor: '#11ab87',
    color: '#fff',
    textAlign: 'center',
  },
  cardStyle2: {
    padding: '12px 15px',
    boxShadow: '1px 1px 5px -1px rgba(0,0,0,0.1)',
    textAlign: 'center',
    backgroundColor: '#2378d9',
    color: '#fff',
  },
  cardStyle3: {
    padding: '12px 15px',
    boxShadow: '1px 1px 5px -1px rgba(0,0,0,0.1)',
    textAlign: 'center',
    backgroundColor: '#d96923',
    color: '#fff',
  },
  subCardStyle: {
    background: '#fff',
    padding: 10,
    color: '#11ab87',
    marginTop: 9,
  },
  subCardStyle2: {
    background: '#fff',
    padding: 10,
    color: '#2378d9',
    marginTop: 9,
  },
  subCardStyle3: {
    background: '#fff',
    padding: 10,
    color: '#d96923',
    marginTop: 9,
  },
};

/**
 * [Component] OrganizationStructure
 * --
 */
const En = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-1 col-md-12"></div> */}
            <div className="col-lg-5 col-md-12">
              <div>
                <img
                  src="resources/discover_saha/0_Mayor_greetings_pic0.jpeg"
                  width="95%"
                  style={{ borderRadius: 12 }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h2>Organization Chart of the Saha District Office</h2>
                <p className="align-justify">
                  As part of the Saha District Office, we believe in
                  transparency and clear communication. To foster a better
                  understanding of our administrative structure, we present the
                  comprehensive Organization Chart of the Saha District Office.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section
        className="service-overview section bg-gray"
        style={{ overflowX: 'scroll' }}
      >
        <div
          className="container-fluid"
          style={{ width: '100%', minWidth: 1550 }}
        >
          <div className="row" style={{ minWidth: 1800 }}>
            {/* === in row === */}

            {/* #1 */}
            <div className="col-5"></div>
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Saha District Residents</h5>
              </div>
            </div>
            <div className="col-5"></div>
            {/* #1 */}

            {/* #1 */}
            <div className="col-5"></div>
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Mayor of Saha District</h5>
              </div>
            </div>
            <div className="col-5"></div>
            {/* #1 */}

            {/* #1 */}
            <div className="col-5"></div>
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Deputy Mayor</h5>
              </div>
            </div>
            <div className="col-5"></div>
            {/* #1 */}

            {/* #2 */}
            {/* === 기획실 === */}
            <div className="col-1" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Planning Office</h5>
              </div>
            </div>

            {/* === 소통 감사실 === */}
            <div className="col-1" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Communication Audit Office</h5>
              </div>
            </div>

            {/* === 자치행정국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Local Administration Bureau</h5>

                {[
                  'General Affairs Division',
                  'Lifelong Education Division',
                  'Finance Division',
                  'Tax Division I',
                  'Tax Division 2',
                  'Civil Registry & Passport  Division',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>

            {/* === 경제문화국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Economic and Cultural Bureau</h5>

                {[
                  'Economic Promotion Division',
                  'Culture, Tourism, and Public Relations Division',
                  'Strategic Business Division',
                  'Land Information Division',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>

            {/* === 교통환경국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">
                  Transportation and Environment Bureau
                </h5>

                {[
                  'Traffic Administration Division',
                  'Resource Circulation Division',
                  'Environmental Sanitation',
                  'Forestry and Green Space Division',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>

            {/* === 주민복지국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Resident Welfare Bureau</h5>

                {[
                  'Welfare Policy Division',
                  'Gender Equality and Family Division',
                  'Basic Livelihood Security Division',
                  'Welfare Business Division',
                  'Job Welfare Division',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>

            {/* === 도시안전국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">Urban Safety Bureau</h5>

                {[
                  'Safety Management Division',
                  'Urban Regeneration Division',
                  'Construction Division',
                  'Urban Development Division',
                  'Building Division',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>
            {/* #2 */}

            {/* === in row === */}
          </div>

          <br />
          <br />
          <div className="row">
            {/* === in row === */}
            <div className="col-2" />
            {/* === 보건소 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle2}>
                <h5 className="fs115em">Health Center</h5>

                {[
                  'Health Administration Division',
                  'Health Promotion Division',
                ].map((item, index) => (
                  <div style={styles.subCardStyle2} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>
            {/* === 을숙도문화회관 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle2}>
                <h5 className="fs115em">Eulsukdo Cultural Center</h5>
              </div>
            </div>
            {/* === 다대도서관 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle2}>
                <h5 className="fs115em">Dadae Library</h5>
              </div>
            </div>
            {/* === 시설관리사업소 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle2}>
                <h5 className="fs115em">Facilities Management Office</h5>
              </div>
            </div>
            {/* === in row === */}
            <div className="col-2" />
          </div>
          <br />
          <br />

          <div className="row">
            <div className="col-5" />
            {/* === 의회사무국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle3}>
                <h5 className="fs115em">Council Secretariat</h5>

                {[
                  'Council Support',
                  'Legislative Affairs',
                  'Legislative Specialist',
                ].map((item, index) => (
                  <div style={styles.subCardStyle3} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-5" />
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/*  */}
          <div className="row">
            <div className="col-lg-4">
              <div className="content-block">
                <h2>Contact Us</h2>
                <p>
                  <b>Email</b> : admin@newsaha.net
                  <br />
                  <b>Phone</b> : +82-51-220-4000
                  <br />
                  <b>Fax</b> : +82-51-220-4269
                  <br />
                  <b>Address</b> : 12 Nakdongdae-ro 398beon-gil, Saha-gu, Busan
                  Metropolitan City 49328, Republic of Korea
                  <br />
                </p>
              </div>
            </div>

            <div className="col-lg-8">
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1DszLjWrNbYq6pjX1RuQyS5WlHrgZsHo&ehbc=2E312F"
                width="100%"
                // height="520"
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 500,
                  margin: 0,
                  padding: 0,
                }}
              ></iframe>
            </div>
          </div>

          {/*  */}
        </div>
      </section>

      {/*  */}
    </>
  );
};

export default En;
