
import React, { useState } from 'react';
import { getCookie } from 'utils';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';
import { BlogList, BlogDetail } from 'components/BlogView';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';


const {
  intl: { txt: lang },
} = globalObj;

const IntroContainer = styled.div`
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: #FFF5EE; // Adjust background color as needed
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); // Updated shadow
`;

const IntroHeading = styled.h2`
  color: #333;
`;

const IntroText = styled.p`
  color: #666;
`;

const PageIntroduction = ({ language }) => {
  const introText = {
    EN: {
      heading: "Stay Updated on the Latest Developments",
      text: "Discover the Latest Progress and Updates from Knowledge Industry Centers in the Saha District"
    },
    KR: {
      heading: "신평장림 지식산업센터 최신 동향",
      text: "사하구의 지식산업센터의 최신 소식을 확인하세요."
    }
  };

  const currentLang = introText[language] || introText.EN; // Default to English if language is not found

  return (
    <IntroContainer>
      <IntroHeading>{currentLang.heading}</IntroHeading>
      <IntroText>{currentLang.text}</IntroText>
    </IntroContainer>
  );
};

const PageIndex = () => {
  const language = getCookie('country') || 'EN'; // Default to English if no cookie is found
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { postId } = useParams();
  const [detail, setDetail] = useState(null);

  const handleChooseData = (item) => {
    setDetail(item);
    navigate(`${pathname}/${item.id}`);
  };

  return (
    <>
      <PageTitle
        title={lang['kics_progress']}
        breadcrumb={[lang['kics'], lang['kics_progress']]}
        backgroundImage={'resources/KIC/3_KICProgress_pic1.jpg'}
      />
      <PageIntroduction language={language} />
      {!postId ? (
        <BlogList blogType="kic-progress" onChooseData={handleChooseData} />
      ) : (
        <BlogDetail data={detail} blogType="kic-progress" postId={postId} />
      )}
    </>
  );
};

export default PageIndex;