import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { Loading } from 'components';
import { getCookie } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons'; // Import the handshake icon

const defaultValue = {
  name: '',
  type: '',
  email: '',
  phone: '',
  company: '',
  content: '',
};

const ContactContainer = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
`;

const ContactTitle = styled.h3`
  font-size: 20px; /* Reduced font size */
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ContactInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
`;

const ContactTextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
`;

const ContactButton = styled.button`
  background-color: #11ab87;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }
`;

const Form2 = () => {
  const language = getCookie('country');
  const [loadingFlag, setLoading] = useState(false);
  const [data, setData] = useState(defaultValue);
  const [selectedType, setSelectedType] = useState('');

  const onChange = (name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onTypeChange = (value) => {
    setSelectedType(value);
    onChange('type', value);
  };

  const sendEmail = () => {
    setLoading(true);
    const { name, type, email, phone, company, content } = data;

    if (!name || !type || !email || !phone || !company || !content) {
      alert('Please enter all details');
      setLoading(false);
      return;
    }

    const params = {
      name,
      type,
      email,
      phone,
      company,
      content,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_2,
        params,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          alert('Complete!');
          setData(defaultValue);
          setSelectedType(''); // Reset selected type after submission
        },
        (error) => {
          alert('Failed');
          setLoading(false);
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Loading visible={loadingFlag} />
      <ContactContainer>
        <ContactTitle>
        <FontAwesomeIcon icon={faHandshake} className="mr-2" /> {/* Add the handshake icon */}
          {language !== 'KR' ? 'Investment Inquiry' : '투자 문의'}
        </ContactTitle>
        <ContactForm
          name="contact_form"
          className="default-form contact-form"
          action="!#"
          method="post"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <ContactInput
                  type="text"
                  name="Name"
                  placeholder="Name"
                  required=""
                  value={data.name}
                  onChange={(e) => onChange('name', e.target.value)}
                />
              </div>
              <div className="form-group">
                <ContactInput
                  type="email"
                  name="Email"
                  placeholder="Email"
                  required=""
                  value={data.email}
                  onChange={(e) => onChange('email', e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <ContactInput
                  type="text"
                  name="Phone"
                  placeholder="Phone"
                  required=""
                  value={data.phone}
                  onChange={(e) => onChange('phone', e.target.value)}
                />
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  name="type"
                  value={selectedType}
                  onChange={(e) => onTypeChange(e.target.value)}
                >
                  <option value="" disabled selected>
                    {language !== 'KR' ? 'Select an Option' : '옵션 선택'}
                  </option>
                  <option value="Investment">
                    {language !== 'KR' ? 'Investment' : '투자'}
                  </option>
                  <option value="Venture Capital">
                    {language !== 'KR' ? 'Venture Capital' : '벤처 캐피탈'}
                  </option>
                  <option value="Mergers and Acquisitions">
                    {language !== 'KR'
                      ? 'Mergers and Acquisitions'
                      : '합병과 인수'}
                  </option>
                  <option value="Other">
                    {language !== 'KR' ? 'Other' : '기타'}
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <ContactInput
                  type="text"
                  name="affiliation"
                  placeholder="Affiliation"
                  required=""
                  value={data.company}
                  onChange={(e) => onChange('company', e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <ContactTextArea
                  name="form_message"
                  placeholder="Your Message"
                  required=""
                  value={data.content}
                  onChange={(e) => onChange('content', e.target.value)}
                />
              </div>
              <div className="form-group text-center">
                <ContactButton type="button" onClick={sendEmail}>
                  {language !== 'KR' ? 'Submit Now' : '제출하기'}
                </ContactButton>
              </div>
            </div>
          </div>
        </ContactForm>
      </ContactContainer>
    </>
  );
};

export default Form2;
