import React from 'react';
import { useNavigate } from 'react-router-dom';

const ExpoBanner = () => {
  const navigate = useNavigate();

  return (
    <section
      className="service-tab-section section bg-gray hover-opacity"
      onClick={() => navigate('/kic-overview')}
      style={{
        backgroundColor: '#f0f0f5',
        backgroundImage: `url("/kic_banner.png")`,
        backgroundSize: 'cover', // This will cover the entire area of the section
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        padding: 0,
        width: '100%',
        position: 'relative',
      }}
    >
      <style>
        {`
          .service-tab-section {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          /* Style for larger screens */
          @media (min-width: 750px) {
            .service-tab-section {
              height: 170px; // Adjust as needed for larger screens
              background-position: center center;
            }
          }

          /* Style for mobile screens */
          @media (max-width: 767px) {
            .service-tab-section {
              height: 75px; // Set a fixed height for mobile screens
              background-position: top center; // Adjust background position for mobile
              background-size: auto 100%; // Adjust background size for mobile
            }
          }
        `}
      </style>
    </section>
  );
};

export default ExpoBanner;
