import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getCookie } from 'utils';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';
import { BlogList, BlogDetail } from 'components/BlogView';
import styled from 'styled-components';

const {
  intl: { txt: lang },
} = globalObj;

const IntroContainer = styled.div`
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: #F5F5F5	; // Adjust background color as needed
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); // Updated shadow
`;

const IntroHeading = styled.h2`
  color: #333;
`;

const IntroText = styled.p`
  color: #666;
`;

const PageIntroduction = ({ language }) => {
  const introText = {
    EN: {
      heading: "Fostering Regional Development in Saha",
      text: "Explore how local government, businesses, and universities in Saha District collaborate to nurture regional industrial growth, job creation, and talent development."
    },
    KR: {
      heading: "사하구의 지역상생발전 생태계",
      text: "사하구의 지자체와 기업, 대학이 협력해 지역 산업을 육성하고, 일자리 창출, 인재 양성하는 지역상생발전 생태계 소식을 만나보세요."
    }
  };

  const currentLang = introText[language] || introText.EN;

  return (
    <IntroContainer>
      <IntroHeading>{currentLang.heading}</IntroHeading>
      <IntroText>{currentLang.text}</IntroText>
    </IntroContainer>
  );
};

const SahaEdu = () => {
  const language = getCookie('country') || 'EN';
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { postId } = useParams();
  const [detail, setDetail] = useState(null);

  const handleChooseData = (item) => {
    setDetail(item);
    navigate(`${pathname}/${item.id}`);
  };

  return (
    <>
      <PageTitle
        title={lang['saha_edu']}
        breadcrumb={[lang['saha_life'], lang['saha_edu']]}
        backgroundImage={'resources/saha_life/2_LIFESTYLE_TRANSPORTATION_Pic1.JPG'}
        backgroundPosition={'center 65%'}
      />
      <PageIntroduction language={language} />
      {!postId ? (
        <BlogList blogType="edu" onChooseData={handleChooseData} />
      ) : (
        <BlogDetail data={detail} blogType="edu" postId={postId} />
      )}
    </>
  );
};

export default SahaEdu;
