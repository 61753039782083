/**
 *
 *
 */

import React from 'react';

/**
 * [Component] DistrictHousing
 * --
 */
const En = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic2.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0 ">
              <div className="content-block">
                <h2>
                  Embracing Change for a Prosperous Future
                  <br />
                  <small>
                    "Creating a 15-minute City - a Haven of Convenience and
                    Culture"
                  </small>
                </h2>
              </div>
              <p className="align-justify">
                The Saha District is divided into four areas: (1)Hadan and
                Dangni, (2)Sinpyeong and Jangrim, (3)Goejeong, and (4)Gamcheon
                and Gupyeong. These areas are planned to be developed into
                'Happy City Saha,' a concept where all essential daily amenities
                are reachable within 15 minutes.
                <br />
                <br />
                We look to successful examples of '15-minute cities' from around
                the globe to inform our vision. These innovative urban models
                focus on creating self-sufficient neighborhoods, where people
                can meet their needs within a short walk or bike ride from their
                homes. This design promotes sustainability, improves quality of
                life, and helps build a strong sense of community.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic3.jpg"
                width="95%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>
                  <small>
                    Investing in Children's Libraries as Cultural Centers
                  </small>
                </h2>
              </div>
              <p className="align-justify">
                In April 2023, the Saha District celebrated the grand opening of
                an expansive children's cultural complex within the Dadae
                Library. Occupying an impressive area of 614.31㎥, the space
                caters to both children and parents by offering a broad range of
                activities that harmoniously blend play, reading, digital
                experiences, and learning. The complex features a digital
                learning hall for immersive virtual reality storytelling and
                engaging play experiences, a dedicated classroom for coding
                activities linked with picture book reading, and an Augmented
                Reality (AR) zone where children can experience the magic of AR
                storybooks. Since its inauguration, the complex has achieved
                overwhelming success, with over 1500 visitors flocking to the
                venue daily on weekends, thereby solidifying its position as a
                valuable community asset in the Saha District.
              </p>
              <br />
              <br />
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>
                  <small>
                    Eco-restoration of Goejeong Stream and Public Transportation
                    Improvements
                  </small>
                </h2>
              </div>
              <p className="align-justify">
                In addition to building community spaces, we're also committed
                to restoring Goejeong Stream to its natural state as an
                ecological river once issues like residential sewage treatment
                are resolved. The concept of a 15-minute city includes not only
                walkable spaces but also the enhancement of public
                transportation. We're pushing for the expansion of the urban
                railway lines such as Sasang-Hadan-Noksan line and Songdo line.
                The construction of the second Daeti tunnel is also proceeding
                without interruption.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>
                  <small>
                    Station Area Development and Youth Startup Housing
                  </small>
                </h2>
              </div>
              <p className="align-justify">
                We're pursuing mixed-use development centered around stations
                such as Goejeong station. The plan is to supply youth startup
                housing where residence, jobs, commerce, and culture coexist.
                <br />
                More initiatives in line with the 15-minute city concept are
                underway, all designed to make the Saha District a more
                convenient and culturally-rich place to live. We look forward to
                sharing more updates as these projects progress.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic4.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>Transforming Public Administration and Local Economy</h2>
              </div>
              <p>
                As we embark on this journey to shape the Saha District into a
                model of future development, we're committed to transparency and
                to sharing our plans with the community. Let's delve into the
                specific strategies that are being set in motion, revolving
                around strategic land use planning, public administration
                service enhancement, local economy boost, and better land use
                efficiency.
              </p>
              <br />
              <br />
              <br />
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: '#333' }}>
                Building a New Saha District Office:{' '}
                <small>Enhancing the Public Administration Service</small>
              </h3>
              <hr />
              <p className="align-justify">
                We're reconstructing the Saha District Office that has been
                showing signs of age and deteriorating functionality. With this
                move, we aim to create an administrative hub that's modern,
                well-equipped, and attuned to the needs of our expanding
                community. The plan is not just about refurbishment but also
                expansion, as we look to secure additional land to accommodate
                the growing requirements of our District Office.
                <br />
                This initiative is designed to increase accessibility and
                convenience for local residents, offering an improved quality of
                public administrative services and providing more public spaces.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: '#333' }}>
                Creating an Innovation District Linked with Universities:{' '}
                <small>Boosting the Local Economy</small>
              </h3>
              <hr />
              <p className="align-justify">
                Recognizing the power of academia and industry cooperation, we
                are planning to inject new vitality into the areas surrounding
                local universities, specifically Dong-A University (Hadan-dong)
                and Busan Health University (Goejeong-dong). By establishing
                innovative activation zones and supporting youth
                entrepreneurship programs in these areas, we aim to create a
                symbiotic relationship between the universities and the
                surrounding areas. Through these measures, we aspire to
                establish a sustainable model for regional innovation and
                economic revitalization.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic5.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>
                  Advancing Development through Strategic Land Use Planning
                </h2>
              </div>
              <p className="align-justify">
                Our strategies for long-term development center on judicious
                modifications to our land use plans, ensuring a balanced
                approach that fosters community growth and future
                sustainability.
              </p>
              <br />
              <br />
              <br />
              <br />
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: '#333' }}>
                Efficient Land Use: Ensuring Better Land Use Efficiency
              </h3>
              <hr />
              <p className="align-justify">
                In alignment with the Busan Urban Plan, we're pushing for
                changes in land use in central areas like Hadan, Goejeong,
                Gamcheon, and Jangrim. By redesignating some quasi-residential
                areas to neighboring commercial zones, we aim to strengthen the
                foundation of Busan's western metropolitan center. This
                transition is designed to optimize land use efficiency and
                enhance the centrality of these regions.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: '#333' }}>
                Enhanced Land Use in Subway Station Areas: Strategic Urban
                Densification
              </h3>
              <hr />
              <p className="align-justify">
                To revitalize regional vibrancy and foster sustainable urban
                growth, we're focusing on the densification of land use around
                key subway stations like Dadaepo Beach Station, Natgae Station,
                and the New Jangnim Station. The emphasis is on unlocking the
                potential of these areas for residential and commercial
                activities, contributing to a more dynamic Saha District.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-12 col-md-12">
              <br />
              <br />
              <h3 style={{ color: '#333' }}>
                Realization of Quasi-Industrial Area Use and Changes in Old
                Factory Areas
              </h3>
              <hr />
              <p className="align-justify">
                To enrich the living environment of our residents, we're
                transforming mixed-use and incompatible areas within the
                quasi-industrial sector. Simultaneously, we're promoting the
                relocation of old factories to more suitable areas. Our goal is
                to mitigate any adverse effects these factories may have on
                residential environments and improve the overall quality of
                life.
                <br />
                Through these concerted efforts, we strive to create a more
                sustainable, livable, and prosperous Saha District for our
                residents. As we continue to adapt and change, we keep the needs
                and aspirations of our residents at the forefront, ensuring a
                bright future for all in Saha District.
                <br />
                <br />
                We welcome your thoughts, questions, and feedback as we move
                forward with these plans. Together, we can embrace change for a
                prosperous future.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic6.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
              <br />
              <br />
              <br />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>
                  Enhancing Saha's Charm through the Creation of Integrated
                  Trails and Spaces
                </h2>
              </div>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: '#333' }}>
                Creation of Seunghaksan Mountain Trail
              </h3>
              <hr />
              <p className="align-justify">
                Seunghaksan Mountain, the highest peak in Saha District, is a
                rich natural resource and a symbolic representation of our area.
                <br />
                In the pursuit of enhancing the allure of the Saha District, we
                are ambitiously undertaking a project that centers around the
                majestic Seunghaksan Mountain, the district's symbolic natural
                resource. Recognizing that the current trails leading to the
                mountain are fragmented and create an obstacle for pedestrians
                wanting to experience the mountain's tranquility, the plan is to
                establish a well-connected, continuous trail around Seunghaksan
                Mountain.
                <br />
                <br />
                This comprehensive trail will not only leverage the existing
                paths but also take into account factors like accessibility,
                usability, and scenic views to offer a fulfilling experience.
                The project will unfold in a phased manner, commencing with an
                in-depth investigation of the current status of the mountain
                trails, followed by resource mapping for the planned trail,
                selection of the optimal route, content development based on the
                discovered resources, and eventually, the execution of the
                project. By facilitating an immersive experience of Seunghaksan
                Mountain's beauty, the Saha District aims to amplify its charm
                and appeal to residents and visitors alike.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: '#333' }}>
                Development of Pedestrian Paths around the 600-year-old Sophora
                Tree
              </h3>
              <hr />
              <p className="align-justify">
                We envision transforming the Goejeong area into a magnet of
                attraction by capitalizing on its unique natural and historical
                resources. Goejeong, a central hub within the Saha District,
                boasts distinctive features such as two venerable 600-year-old
                Sophora trees and a tranquil park, among other attractions.
                However, the existing lack of connectivity among these
                attractions has been identified as an area needing improvement.
                <br />
                <br />
                We will design and implement a comprehensive network of
                pedestrian paths that enhance accessibility and utilization of
                Goejeong's unique resources. These paths will connect the area's
                features, including the Sophora trees, and showcase Goejeong's
                historical and cultural identity. The project will unfold in
                phases, involving resource investigation, content development,
                collaboration with nearby commercial areas, and the
                implementation of a captivating landscape design. This
                initiative is expected to drive social and economic
                revitalization, solidifying Goejeong's position as a prestigious
                destination within the district.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-12 col-md-12">
              <br />
              <br />
              <h3 style={{ color: '#333' }}>
                Creation of Saha Seafront Trails
              </h3>
              <hr />
              <p className="align-justify">
                The Saha Seafront Trail project aims to invigorate the local
                economy by creating a coastal trail that encompasses the Dadaepo
                Beach and Nakdong River areas. As part of our commitment to
                enhancing Saha district's natural allure and fostering community
                engagement, we are planning to develop a scenic coastal trail
                spanning the breathtaking Dadaepo Beach and Nakdong River areas.
                Recognizing the current limitations of the pedestrian experience
                along the Nakdong River due to an auto-centric design, our focus
                is on improving walkability and enjoyment of these surroundings.
                The project will primarily target Dadae-ro and Riverside
                Boulevard, covering the expanse from Dadaepo Beach to the
                Nakdong River Estuary and Eulsukdo Entrance.
                <br />
                <br />
                Envisioned as a phased development, we aim to redesign the
                existing road layout to better accommodate pedestrians, create a
                vibrant Saha Sea Square, and develop a picturesque promenade or
                walking trail. Part of our plans also includes cultivating
                unique sunset experience content along the trail. We firmly
                believe that these initiatives will not only add a prestigious
                tourist trail representing the southwest region, but will also
                stimulate the local economy, reinforcing Saha as a district of
                prosperity and vibrant community life.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic7.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <h3 style={{ color: '#333' }}>
                Adaptability and Flexibility in Implementation
              </h3>
              <br />
              <p className="align-justify">
                Please note that this is a conceptual representation and may be
                subject to changes during the actual implementation based on
                feasibility, financial considerations, and community feedback.
              </p>
              <br />
              <br />

              <h3 style={{ color: '#333' }}>
                Creating a Better Saha: Your Support Makes it Possible
              </h3>
              <br />
              <p className="align-justify">
                The Saha District Office is committed to making our region an
                ever more attractive place to live, work, and visit. We thank
                you for your support and understanding as we strive to improve
                our district for the betterment of all.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>
    </>
  );
};

export default En;
