/**
 *
 *
 */

import React from 'react';

const styles = {
  articleTitle: {
    color: '#333',
    marginBottom: 15,
  },
  articleWarpper: {
    textAlign: 'center',
  },
  articleImage: {
    width: '100%',
    height: '100%',
    maxHeight: 305,
    minHeight: 235,
    margin: '0 auto',
    backgroundSize: 'cover',
    // backgroundSize: 'cover 100%',
    border: '1px solid #dadada',
    borderRadius: 20,
  },
};

/**
 * [Component] Sample
 * --
 */
const Ko = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-12" style={{ textAlign: 'center' }}>
              {/* 이미지 */}
              <div
                style={{
                  width: '99%',
                  height: '90%',
                  minHeight: 270,
                  margin: '0 auto',
                  backgroundSize: 'cover',
                  border: '1px solid #dadada',
                  borderRadius: 20,
                  backgroundImage: `url("resources/WhySaha/1_Saha_Vision_pic1.JPG")`,
                }}
              ></div>
              <p className="italic">당리동과 하단동 전경</p>
            </div>

            {/* 내용 */}
            <div className="col-xl-8 col-lg-12">
              <div className="content-block">
                <h2>
                  자연과 삶이 어우러진 앙상블 도시
                  <br />
                  <small style={{ fontSize: '0.65em' }}>
                    "자연의 평온함과 도시 생활의 활기찬 리듬이 조화를
                    이루는SAHA"
                  </small>
                </h2>
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                  eius optio repellendus quasi nisi vitae laboriosam explicabo
                  eligendi sapiente in. Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Beatae, dolorum esse tempora id architecto
                  laboriosam.
                </p> */}
                <p style={{ paddingRight: 35 }}>
                  도시의 활력과 자연의 매혹적인 아름다움을 결합한 '앙상블 도시'
                  사하구는, 다채로운 생활 경험을 제공하는 것을 목표로 하고
                  있습니다. 생동감 넘치는 시장부터 평온한 공원, 고요한 바다부터
                  붐비는 항구, 마음을 평온을 주는 낙동강부터 웅장한 산맥까지,
                  사하의 모든 도시 요소는 사하의 다양한 자연 경관과 조화롭게
                  디자인되었습니다. 사하구의 비전은 모든 주민들이 행복을 느낄 수
                  있는 도시를 만드는 것입니다. 사하구에서는 자연의 평온함과
                  도시의 에너지가 완벽한 균형을 이루도록 지속적인 노력을
                  기울이고 있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* === 타이틀 === */}
            <div className="col-md-12">
              <div className="content-block">
                <h2>사하구의 비전</h2>
                <h4>"조화로운 미래를 향한 우리의 로드맵"</h4>
                <p>
                  사하구는 구민 모두의 행복을 추구하며, 자연적인 풍경 속에서
                  모든 삶의 영역이 조화롭게 어우러지는 '앙상블 도시'를 목표로
                  하고 있습니다
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div
          className="container"
          style={{ paddingRight: 26, paddingLeft: 26 }}
        >
          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-12">
              <h3 style={styles.articleTitle}>
                1.삶의 가치를 더하는 명품 주거 도시
              </h3>
            </div>
            {/* #1 */}
            <div className="col-lg-6" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic2.jpeg")`,
                }}
              ></div>
              <p className="italic">다대마린시티</p>
              <br />
              <br />
              <br />
            </div>
            {/* #1 */}
            <div className="col-lg-6">
              <p>
                사하구는 쾌적한 생활 환경을 조성하며 품질 높은 주거 공간을
                제공하고자 합니다. 이러한 노력의 일환으로, 우리는 편리한 행정
                인프라를 구축하여 주민들이 편리하게 행정 서비스를 이용할 수
                있도록 하고 있습니다. 또한, 도시 재생 뉴딜 사업을 통해 구역의
                역동성을 회복하고 주민들의 삶의 질을 향상시키는데 집중하고
                있습니다. 지역 발전에 대한 체계적이고 실질적인 방안을 마련하고,
                이를 통해 사하구가 더욱 발전하고 번영하는 데 기여하고자 합니다.{' '}
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-12">
              <h3 style={styles.articleTitle}>2. 모두가 누리는 복지 도시</h3>
            </div>

            {/* #1 */}
            <div className="col-lg-6">
              <p>
                사하구는 다양한 복지 인프라를 확충하며 모든 세대의 복지를 위한
                맞춤형 서비스를 제공하고 있습니다. 공공 복지 시설을 구축하고
                아동을 위한 교육 및 문화공간을 확대하며, 노인, 참전유공자,
                청소년, 청년 등 다양한 연령대와 상황의 주민들이 필요로 하는 복지
                서비스를 제공하며, 사하구의 주민 모두가 풍요롭고 안정적인 삶을
                즐길 수 있도록 노력하고 있습니다.
              </p>
            </div>
            {/* #1 */}
            <div className="col-lg-6" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_Pic9.jpg")`,
                }}
              ></div>
              <p className="italic">{'<아동참여기구 발대식>'}</p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-12">
              <h3 style={styles.articleTitle}>
                3. 함께 잘 사는 스마트 경제 도시
              </h3>
            </div>

            {/* #1 */}
            <div className="col-lg-6" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic4.png")`,
                }}
              ></div>
              <p className="italic">지식산업센터</p>
              <br />
              <br />
              <br />
            </div>
            {/* #1 */}
            <div className="col-lg-6">
              <p>
                사하구는 스마트 경제 도시를 목표로 하며 다양한 지원 사업을 통해
                일자리 창출, 기업 활성화, 그리고 지역경제 활력 증진을 위한
                노력을 진행하고 있습니다. 이러한 행보는 취업 시장의 활성화, 상권
                발전, 그리고 어촌 경제와 AI 기반 산업의 발전 등을 중심으로
                이루어지며, 이를 통해 구민 모두가 함께 잘 사는 스마트 경제
                도시를 구축해 나가고 있습니다.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-12">
              <h3 style={styles.articleTitle}>
                4. 가덕도 신공항을 잇는 교통 중심 도시
              </h3>
            </div>

            {/* #1 */}
            <div className="col-lg-6">
              <p>
                교통 허브로서 사하구는 다양한 교통 인프라 개발을 통해 신속하고
                효율적인 교통망을 구축하고 있습니다. 부산형 급행철도(BuTX)와
                가덕도 신공항과 다대포 간의 도로망 확충, 그리고 하단-녹산 및
                사상 간 도시철도 건설 등을 통해 교통망을 강화하고 있으며,
                승학터널 민간투자 사업과 제2대티터널 건설 등을 추진하여 교통
                통행량의 효율성을 높이는 데 집중하고 있습니다.
              </p>
            </div>
            {/* #1 */}
            <div className="col-lg-6" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic5.png")`,
                }}
              ></div>
              <p className="italic">부산형 급행철도</p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-12">
              <h3 style={styles.articleTitle}>
                5. 자연과 문화가 어우러진 힐링 관광도시
              </h3>
            </div>

            {/* #1 */}
            <div className="col-lg-6" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic3.jpeg")`,
                }}
              ></div>
              <p className="italic">{'<을숙도 복합힐링파크>'}</p>
              <br />
              <br />
              <br />
            </div>
            {/* #1 */}
            <div className="col-lg-6">
              <p>
                사하구는 자연과 문화가 어우러진 힐링 관광 도시로서 다양한 관광
                개발 사업과 여가 공간 조성, 문화•예술 프로그램 운영을 통해
                주민들의 삶의 질 향상에 기여하고 있습니다
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-12">
              <h3 style={styles.articleTitle}>
                6. 쾌적하고 살기 좋은 녹색 안전도시
              </h3>
            </div>

            {/* #1 */}
            <div className="col-lg-6">
              <p>
                사하구는 다양한 사업을 통해 쾌적하고 살기 좋은 녹색 안전 도시를
                목표로 하고 있습니다. 여러 환경 개발 및 관리 사업, 안전도시 조성
                사업, 그리고 하천수질 및 대기 환경 개선 사업을 추진하며,
                지속적으로 생태적이고 안전한 도시 환경을 구축하고 있습니다. 이를
                통해 구민들이 안전하고 건강한 환경에서 살아갈 수 있도록 지원하고
                있습니다
              </p>
            </div>
            {/* #1 */}
            <div className="col-lg-6" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic7.jpg")`,
                }}
              ></div>
              <p className="italic">을숙도철새공원</p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}

          <br />
          <br />
          {/* === row === */}
        </div>
      </section>
    </>
  );
};

export default Ko;
