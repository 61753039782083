import React, { useEffect, useState, useMemo } from 'react';
import { db } from 'firebase.config';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { Loading } from 'components';
import { getCookie } from 'utils';
import moment from 'moment';
import BlogItem from './BlogItem';
import styled from 'styled-components';

const PostContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const PostItem = styled.div`
  flex-basis: calc(50% - 10px);
  box-sizing: border-box;
  margin-bottom: 20px;
  overflow: hidden;
  height: auto;

  @media (max-width: 768px) {
    flex-basis: 100%;
    margin-bottom: 15px;
  }
`;

const ITEMS_PER_PAGE = 6;

const QuickLinkButton = styled.button`
  background-color: #11ab95;
  color: #ffffff;
  padding: 3px 9px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }

  &:active {
    background-color: #077a57;
    transform: scale(1);
  }
`;

const BlogList = ({ blogType = 'download', onChooseData, viewType = 'grid' }) => {
  const navigate = useNavigate();
  const token = getCookie('NEWSAHA_ACCESS_TOKEN');
  const isAuthentication = !!token;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const today = useMemo(() => moment(), []);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchBlogData = async () => {
      setIsLoading(true);
      try {
        const docRef = collection(db, 'board', blogType, 'datas');
        const snapshot = await getDocs(docRef);

        const formattedData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setData(formattedData.sort((a, b) => moment(b.date).unix() - moment(a.date).unix()));
      } catch (e) {
        console.error('Error fetching blog data:', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogData();
  }, [blogType]);

  const newPosts = useMemo(() => 
    data.filter((item) => today.diff(item.date, 'days') <= 7)
  , [data, today]);

  const mainPosts = useMemo(() => 
    data.filter(item => !newPosts.includes(item))
  , [data, newPosts]);

  const totalPages = Math.ceil(mainPosts.length / ITEMS_PER_PAGE);

  const setPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const itemsForCurrentPage = mainPosts.slice(startIndex, endIndex);

  const links = [
    {
      title: 'About Saha',
      path: '/history-culture',
    },
    {
      title: 'Saha Bulletin',
      path: '/saha-district-bulletin',
    },
    {
      title: 'Saha Newsletters',
      path: '/newsletters',
    },
    {
      title: 'Lifestyle & Transportation',
      path: '/lifestyle-transportation',
    },
    {
      title: 'KIC Progress',
      path: '/kic-progress',
    },
    {
      title: 'SahaEdushere',
      path: '/saha-edu',
    },
  ];

  return (
    <React.Fragment>
      <section className="blog-section style-four section" style={{ paddingTop: 55 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {isAuthentication && (
                <button
                  className="btn btn-light btn-sm float-end"
                  onClick={() => navigate(`/posts/create/${blogType}`)}
                  style={{
                    backgroundColor: 'YourBackgroundColor',
                    color: 'YourTextColor',
                    padding: '10px 20px', // Adjust padding for larger size
                    fontSize: '16px', // Increase font size for larger size
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s, transform 0.3s',
                  }}
                >
                  + New Post
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex flex-wrap justify-content-between">
                {links.map((link) => (
                  <Link
                    key={link.path}
                    to={link.path}
                  >
                    <QuickLinkButton
                      className="mb-2"
                      style={{
                        backgroundColor: 'YourBackgroundColor',
                        color: 'YourTextColor',
                      }}
                    >
                      {link.title}
                    </QuickLinkButton>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          {viewType === 'grid' ? (
            // Grid view without "New post" section
            <>
              <div className="row">
                {newPosts.slice(0, 3).map((item) => (
                  <div className="col-lg-4" key={item.id}>
                    <BlogItem item={item} type="top" onChooseData={onChooseData} />
                  </div>
                ))}
              </div>
              <div className="row mt-4">
                {isLoading ? (
                  <Loading position="relative" visible={isLoading} />
                ) : itemsForCurrentPage.length < 1 ? (
                  <NoDataComponent />
                ) : (
                  <PostContainer>
                    {itemsForCurrentPage.map((item, index) => (
                      <PostItem key={item.id}>
                        <BlogItem item={item} type="default" onChooseData={onChooseData} />
                      </PostItem>
                    ))}
                  </PostContainer>
                )}
              </div>
            </>
          ) : (
            // List view (remains the same)
            <>
              <div className="row">
                <div className="col-lg-4">
                  <div className="right-side">
                    <div className="text-title">
                      <h1 style={{ display: 'flex', justifyContent: 'space-between' }}>
                        Download hub
                      </h1>
                      <br />
                      {isAuthentication && (
                        <button
                          className="btn btn-light btn-lg"
                          onClick={() => navigate(`/posts/create/${blogType}`)}
                        >
                          + New Post
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8" style={{ padding: 0 }}>
                  <ul className="list-group list-group-flush list-group-item-action">
                    {itemsForCurrentPage.map((item, index) => (
                      <ListItem key={item.id} item={item} onChooseData={onChooseData} today={today} />
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )}

          {/* Pagination */}
          {totalPages > 1 && (
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button
                  className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                  onClick={() => setPage(currentPage - 1)}
                  style={{ color: currentPage === 1 ? '#777' : '#11ab87' }} // Change the font color here
                >
                  Previous
                </button>
              </li>
              {pageNumbers.map((pageNumber) => (
                <li
                  className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                  key={pageNumber}
                >
                  <button
                    className={`page-link ${pageNumber === currentPage ? 'active' : ''}`}
                    onClick={() => setPage(pageNumber)}
                    style={{
                      backgroundColor: pageNumber === currentPage ? '#11ab87' : '#fff',
                      color: pageNumber === currentPage ? '#fff' : '#000',
                    }}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button
                  className={`page-link ${currentPage === totalPages ? 'disabled' : ''}`}
                  onClick={() => setPage(currentPage + 1)}
                  style={{ color: currentPage === totalPages ? '#777' : '#11ab87' }} // Change the font color here
                >
                  Next
                </button>
              </li>
            </ul>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

const ListItem = ({ item, onChooseData, today }) => {
  const isNewFile = today.diff(item.date, 'days') <= 3;

  return (
    <li
      className="list-group-item list-group-item-action d-flex justify-content-between align-items-center cursor-pointer"
      onClick={() => onChooseData(item)}
    >
      <span>
        {isNewFile && <span className="badge bg-danger" style={{ color: '#fff' }}>New</span>}
        {item.title || '-'}
      </span>
      <span className="badge rounded-pill">{item.date}</span>
    </li>
  );
};

const NoDataComponent = () => (
  <div style={{
    width: '100%',
    minHeight: 450,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: '1px solid #d1d1d1',
    borderLeft: 'none',
    borderRight: 'none',
  }}>
    <div>
      <h1>No data</h1>
      <br />
      <h6>There's currently no data to show.</h6>
    </div>
  </div>
);

export default React.memo(BlogList);
