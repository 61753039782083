/**
 *
 *
 */

import React from 'react';

const styles = {
  cardWrapper: {
    padding: 3,
  },
  cardStyle: {
    padding: 12,
    boxShadow: '1px 1px 5px -1px rgba(0,0,0,0.1)',
    minHeight: 80,
    backgroundColor: '#11ab87',
    color: '#fff',
    textAlign: 'center',
  },
  cardStyle2: {
    padding: '12px 15px',
    boxShadow: '1px 1px 5px -1px rgba(0,0,0,0.1)',
    textAlign: 'center',
    backgroundColor: '#2378d9',
    color: '#fff',
  },
  cardStyle3: {
    padding: '12px 15px',
    boxShadow: '1px 1px 5px -1px rgba(0,0,0,0.1)',
    textAlign: 'center',
    backgroundColor: '#d96923',
    color: '#fff',
  },
  subCardStyle: {
    background: '#fff',
    padding: 10,
    color: '#11ab87',
    marginTop: 9,
  },
  subCardStyle2: {
    background: '#fff',
    padding: 10,
    color: '#2378d9',
    marginTop: 9,
  },
  subCardStyle3: {
    background: '#fff',
    padding: 10,
    color: '#d96923',
    marginTop: 9,
  },
};

/**
 * [Component] OrganizationStructure
 * --
 */
const Ko = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 col-md-12"></div>
            <div className="col-lg-4 col-md-12">
              <div>
                <img
                  src="resources/discover_saha/0_Mayor_greetings_pic0.jpeg"
                  width="95%"
                  style={{ borderRadius: 12 }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h4>Organization Chart of the Saha District Office</h4>
                <h2>사하구청 조직도</h2>
                <p className="align-justify">
                  사하구청은 투명하고 명확한 소통을 중요하게 생각합니다.
                  사하구청의 행정 구조에 대한 이해를 돕기 위해 사하구청의 종합
                  조직도를 소개합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section
        className="service-overview section bg-gray"
        style={{ overflowX: 'scroll' }}
      >
        <div
          className="container-fluid"
          style={{ width: '100%', minWidth: 1550 }}
        >
          <div className="row" style={{ minWidth: 1800 }}>
            {/* === in row === */}

            {/* #1 */}
            <div className="col-5"></div>
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">
                  사하구민
                  <br />
                  <small>Saha District Residents</small>
                </h5>
              </div>
            </div>
            <div className="col-5"></div>
            {/* #1 */}

            {/* #1 */}
            <div className="col-5"></div>
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">
                  구청장
                  <br />
                  <small>Mayor of Saha District</small>
                </h5>
              </div>
            </div>
            <div className="col-5"></div>
            {/* #1 */}

            {/* #1 */}
            <div className="col-5"></div>
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">
                  부구청장
                  <br />
                  <small>Deputy Mayor</small>
                </h5>
              </div>
            </div>
            <div className="col-5"></div>
            {/* #1 */}

            {/* #2 */}
            {/* === 기획실 === */}
            <div className="col-1" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">기획실</h5>
              </div>
            </div>

            {/* === 소통 감사실 === */}
            <div className="col-1" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">소통 감사실</h5>
              </div>
            </div>

            {/* === 자치행정국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">자치행정국</h5>

                {[
                  '총무과',
                  '평생교육과',
                  '재무과',
                  '세무1과',
                  '세무2과',
                  '민원여권과',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>

            {/* === 경제문화국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">경제문화국</h5>

                {[
                  '경제진흥과',
                  '문화관광 홍보과',
                  '전략사업과',
                  '토지정보과',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>

            {/* === 교통환경국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">교통환경국</h5>

                {['교통행정과', '자원순환과', '환경위생과', '산림녹지과'].map(
                  (item, index) => (
                    <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                      <h5 className="fs115em">{item}</h5>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* === 주민복지국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">주민복지국</h5>

                {[
                  '복지정책과',
                  '여성가족과',
                  '생활보장과',
                  '복지사업과',
                  '일자리 복지과',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>

            {/* === 도시안전국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle}>
                <h5 className="fs115em">도시안전국</h5>

                {[
                  '안전총괄과',
                  '도시재생과',
                  '건설과',
                  '도시정비과',
                  '건축과',
                ].map((item, index) => (
                  <div style={styles.subCardStyle} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>
            {/* #2 */}

            {/* === in row === */}
          </div>

          <br />
          <br />
          <div className="row">
            {/* === in row === */}
            <div className="col-2" />
            {/* === 보건소 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle2}>
                <h5 className="fs115em">보건소</h5>

                {['보건행정과', '건강증진과'].map((item, index) => (
                  <div style={styles.subCardStyle2} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>
            {/* === 을숙도문화회관 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle2}>
                <h5 className="fs115em">을숙도문화회관</h5>
              </div>
            </div>
            {/* === 다대도서관 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle2}>
                <h5 className="fs115em">다대도서관</h5>
              </div>
            </div>
            {/* === 시설관리사업소 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle2}>
                <h5 className="fs115em">시설관리사업소</h5>
              </div>
            </div>
            {/* === in row === */}
            <div className="col-2" />
          </div>
          <br />
          <br />

          <div className="row">
            <div className="col-5" />
            {/* === 의회사무국 === */}
            <div className="col-2" style={styles.cardWrapper}>
              <div style={styles.cardStyle3}>
                <h5 className="fs115em">의회사무국</h5>

                {['의사 업무', '의정 업무', '전문위원'].map((item, index) => (
                  <div style={styles.subCardStyle3} key={`s2-3-${index}`}>
                    <h5 className="fs115em">{item}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-5" />
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/*  */}
          <div className="row">
            <div className="col-lg-4">
              <div className="content-block">
                <h2>Contact Us</h2>
                <p>
                  <b>Address</b> : 부산광역시 사하구 낙동대로398번길 12
                  <br />
                  <b>Phone</b> : +82-51-220-4000
                  <br />
                  <b>Fax</b> : +82-51-220-4269
                  <br />
                  <b>Email</b> : admin@newsaha.net
                  <br />
                </p>
              </div>
            </div>

            <div className="col-lg-8">
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1DszLjWrNbYq6pjX1RuQyS5WlHrgZsHo&ehbc=2E312F&zoom=17"
                // src="https://www.google.com/maps/d/embed?mid=1psqQsgAB9e9c6s6s6iwQYvO6o74&zoom=14"
                width="100%"
                // height="520"
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 500,
                  margin: 0,
                  padding: 0,
                }}
              ></iframe>
            </div>
          </div>

          {/*  */}
        </div>
      </section>

      {/*  */}
    </>
  );
};

export default Ko;
