/**
 *
 *
 */

import React, { useEffect, useState } from 'react';
import { db } from 'firebase.config';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { Loading } from 'components';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import { getCookie } from 'utils';
import { useLocation } from 'react-router-dom';


const ses = new AWS.SES();
console.log('ses: ', ses);

/**
 * [Component] BlogDetail
 * --
 */

const BlogDetail = ({ postId, data, blogType }) => {
  /* ===== Initial ===== */
  const navi = useNavigate();
  const location = useLocation();
  const backToListPath = location.pathname.split('/').slice(0, -1).join('/');
  const token = getCookie('NEWSAHA_ACCESS_TOKEN');
  const isAuthentication =
    !token || token === null || token === undefined || token === ''
      ? false
      : true;

  /* ===== State ===== */
  const [loadingFlag, setLoading] = useState(false);
  const [data2, setData2] = useState(null);
  const [chooseFile, setChooseFile] = useState(
    null
    // '43db3eaf-3fac-a9dc-1f66-25e32f27b11d.pdf'
  );

  /* ===== Hooks ===== */
  const handleRemove = async () => {
    if (!window.confirm('게시글이 영구삭제됩니다. 진행하시겠습니까?')) {
      return;
    }
    setLoading(true);
    try {
      // console.log('blogType: ', blogType);
      // const fdDocument = doc(db, 'board', blogType, 'datas', postId);
      const fdDocument = doc(db, 'board', blogType, 'datas', postId);
      const result = await deleteDoc(fdDocument);

      alert('게시글이 삭제되었습니다.');
      navi(-1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('[BlogDetail] handleRemove Error: ', e);
    }
  };

  /* ===== Hooks ===== */
  /**
   *
   */
  useEffect(() => {
    const call = async () => {
      setLoading(true);
      try {
        const fdDocument = collection(db, 'board', blogType, 'datas');
        const result = await getDocs(fdDocument);
        let newData = null;

        result.forEach((item) => {
          if (postId === item.id) {
            newData = {
              ...item.data(),
              id: item.id,
            };
          }
        });

        setData2(newData);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log('[BlogDetail] useEffect Error: ', e);
      }
    };
    window.scrollTo(0, 0);
    !data && call();
  }, [postId]);

  const post = data ? data : data2;
  const videoLink = post && post.videoLink ? post.videoLink.split('/') : null;
  /* ===== Render ===== */
  return (
    <>
      <Loading visible={loadingFlag} />
      <section
        className="blog-section section style-four style-five"
        style={{
          paddingTop: 30,
        }}
      >
        <div className="container" style={{ maxWidth: 1150 }}>
            {/* Show Edit and Delete buttons only if authenticated */}
          {isAuthentication && (
            <div>
              <button
                className="btn btn-outline-danger"
                onClick={() =>
                  navi(`/posts/create/${blogType}?postId=${postId}&mode=edit`)
                }
              >
                수정
              </button>{' '}
              <button className="btn btn-danger" onClick={handleRemove}>
                삭제
              </button>
            </div>
          )}
           {/* Back to List button visible to all users */}
  <button className="btn btn-outline-primary" onClick={() => navi(backToListPath)}>Back to List</button>
  
          <div className="row">
            <div className="col-lg-12">
              <div className="item-holder">
                {post && (
                  <div
                    className="content-text"
                    style={{
                      padding: '40px 0 0 0 ',
                    }}
                  >
                    {/* === 제목 === */}
                    <h1>{post.title && post.title}</h1>

                    {/* === 태그 === */}
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 8,
                      }}
                    >
                      {!post.tags || post.tags.length < 1 ? (
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            color: '#d1d1d1',
                          }}
                        >
                          등록된 태그가 없습니다.
                        </p>
                      ) : (
                        post.tags.map((item, itemIndex) => (
                          <div
                            key={item}
                            style={{
                              padding: '0 12px',
                              marginRight: 2.5,
                              // borderRadius: '100%',
                              borderRadius: 100,
                              background: '#f1f1f1',
                            }}
                          >
                            {item}
                          </div>
                        ))
                      )}
                    </div>
                    <hr />
                    {/* === 날짜 === */}
                    <span>{post.date ? post.date : '----:--:--'}</span>
                    <hr />

                    {videoLink && (
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        {post.videoLink && (
                          <iframe
                            width="740"
                            height="385"
                            src={`https://www.youtube.com/embed/${
                              videoLink[videoLink.length - 1]
                            }`}
                            // src={post.videoLink}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        )}
                      </div>
                    )}

                    {/* === 본문 === */}
                    <div
                      className="blogContent"
                      style={{
                        minHeight: 300,
                      }}
                      dangerouslySetInnerHTML={{ __html: post.content }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />

          <ul className="list-group" style={{ marginTop: 10 }}>
            {post &&
              post.files &&
              post.files.length > 0 &&
              post.files.map((file) => {
                const size = (file.size / 1024).toFixed(0);
                const displaySize =
                  size >= 1000 ? `${(size / 1000).toFixed(2)}MB` : `${size}KB`;
                return (
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                    key={`${file.id}`}
                  >
                    <div class="ms-2 me-auto">
                      {file.originalName}
                      <div class="fw-bold">{displaySize}</div>
                    </div>
                    <span class="badge  rounded-pill">
                      <button
                        className="btn btn-light btn-md"
                        onClick={() => setChooseFile(file)}
                      >
                        Preview
                      </button>{' '}
                      <a
                        target="_blank"
                        href={`https://s3.ap-northeast-2.amazonaws.com/store.newsaha/download/${file.id}`}
                      >
                        <button className="btn btn-light btn-md">
                          Download
                        </button>
                      </a>
                    </span>
                  </li>
                );
              })}
          </ul>
          <br />
          {/* <hr /> */}

          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <button className="btn btn-dark">목록</button>{' '}
            </div>
            <div>
              <button className="btn btn-dark">{'< 이전'}</button>{' '}
              <button className="btn btn-dark">{'다음 >'}</button>
            </div>
          </div> */}
        </div>
      </section>

      {/* 파일 미리보기 */}
      {chooseFile && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            background: 'rgba(0,0,0,0.35)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 888,
          }}
        >
          <div
            style={{
              padding: 0,
              width: '90%',
              background: '#fff',
              zIndex: 889,
              minHeight: 450,
              maxHeight: 1040,
              height: '100%',
            }}
          >
            <div
              style={{
                width: '100%',
                padding: '10px 25px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <h5>Preview</h5>
              </div>
              <div>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => setChooseFile(null)}
                >
                  Close
                </button>
              </div>
            </div>
            <embed
              src={`https://s3.ap-northeast-2.amazonaws.com/store.newsaha/download/${chooseFile.id}`}
              type={`application/${chooseFile.type ? chooseFile.type : 'pdf'}`}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>
      )}
    </>
  );
};

/* DF */
BlogDetail.defaulProps = {
  data: {
    id: null,
    title: null,
    content: null,
    date: null,
  },
};
export default BlogDetail;
