import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// import { getFirestore, getDocs } from 'firebase/firestore/lite';
// import { addDoc, collection } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,

  experimentalForceLongPolling: true, // this line
  useFetchStreams: false, // and this line
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const fireStore = getFirestore(firebase);

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// async function getFolders(db) {
//   const citiesCol = collection(db, 'folders');
//   const citySnapshot = await getDocs(citiesCol);
//   const cityList = citySnapshot.docs.map((doc) => doc.data());
//   return cityList;
// }

// async function add() {
//   try {
//     const docRef = await addDoc(collection(db, 'folders'), {
//       first: 'Ada',
//       last: 'Lovelace',
//       born: 1815,
//     });
//     console.log('Document written with ID: ', docRef.id);
//   } catch (e) {
//     console.error('Error adding document: ', e);
//   }
// }

export { db };
