/**
 *
 *
 */

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import globalObj from 'locale/intlGlobals';

const {
  intl: { txt: lang },
} = globalObj;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * [Component] HeaderNav
 * --
 */
const HeaderNav = () => {
  /* ===== Props ===== */
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /* ===== State ===== */
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [sideMenu, setSideMenu] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menus] = useState([
    {
      id: 'm-3',
      title: lang['kics'],
      path: '/introduce',
      submenus: [
        {
          id: 's-3-1',
          title: lang['kic_overview'],
          path: '/kic-overview',
        },
        {
          id: 's-3-2',
          title: lang['investment_incentives'],
          path: '/investment-incentives',
        },
        {
          id: 's-3-3',
          title: lang['kics_progress'],
          path: '/kic-progress',
        },
      ],
    },
    {
      id: 'm-1',
      title: lang['why_saha'],
      path: '',
      submenus: [
        {
          id: 's-1-1',
          title: lang['saha_vision'],
          path: '/saha-vision',
          // path: '/vision',
        },
        {
          id: 's-1-2',
          title: lang['development_plan'],
          path: '/development-plan',
          // path: '/plan',
        },
        {
          id: 's-1-3',
          title: lang['history_culture'],
          path: '/history-culture',
          // path: '/history',
        },
        {
          id: 's-4-2',
          title: lang['innovation_tech'],
          path: '/innovation-tech',
        },
      ],
    },
    {
      id: 'm-5',
      title: lang['saha_life'],
      path: '/introduce',
      submenus: [
        {
          id: 's-5-1',
          title: lang['district_housing'],
          path: '/district-housing',
        },
        {
          id: 's-5-3',
          title: lang['lifestyle_transportation'],
          path: '/lifestyle-transportation',
        },
        {
          id: 's-5-4',
          title: lang['saha_edu'],
          path: '/saha-edu',
        },
      ],
    },

    // {
    //   id: 'm-4',
    //   title: lang['strategic_business_division'],
    //   path: '/introduce',
    //   submenus: [
    //   ],
    // },

    {
      id: 'm-6',
      title: lang['saha_updates'],
      path: '/introduce',
      submenus: [
        {
          id: 's-6-1',
          title: lang['saha_district_bulletin'],
          path: '/saha-district-bulletin',
        },
        // {
        //   id: 's-6-1',
        //   title: lang['announcements'],
        //   path: '/announcements',
        // },
        // {
        //   id: 's-6-2',
        //   title: lang['press_release'],
        //   path: '/press_release',
        // },
        {
          id: 's-6-3',
          title: lang['newsletters'],
          path: '/newsletters',
        },
        {
          id: 's-6-4',
          title: lang['downloads'],
          path: '/downloads',
        },
      ],
    },
    {
      id: 'm-7',
      title: lang['support'],
      path: '/introduce',
      submenus: [
        {
          id: 's-7-2',
          title: lang['inquiry_form'],
          path: '/inquiry-form',
        },
        {
          id: 's-7-3',
          title: lang['helpful_links'],
          path: '/helpful-links',
        },
      ],
    },

    {
      id: 'm-2',
      title: lang['discover_saha'],
      path: '',
      submenus: [
        {
          id: 's-2-1',
          title: lang['mayors_greetings'],
          path: '/greetings',
        },
        {
          id: 's-2-2',
          title: lang['organization_structure'],
          path: '/organization-structure',
        },
        {
          id: 's-7-1',
          title: lang['location_info'],
          path: '/location-info',
        },
        // {
        //   id: 's-4-1',
        //   title: lang['development_overview'],
        //   path: '/division-overview',
        // },
      ],
    },
  ]);

  /* ===== Hooks ===== */
  useEffect(() => {
    setLoadingFlag(true);
    const call = () => {
      window.scrollTo(0, 0);
      setSideMenu(false);
      setTimeout(() => {
        setLoadingFlag(false);
      }, 350);
    };
    call();
  }, [pathname]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { width } = windowDimensions;
  // console.log('current width: ', width);
  // console.log('pathname: ', pathname);

  /* ===== Render ===== */
  return (
    <>
      <Loading visible={loadingFlag} />
      {/* === === */}
      {width < 993 && (
        <div
          style={{
            position: 'fixed',
            bottom: 17,
            right: 10,
            zIndex: 998,
          }}
        >
          {sideMenu === false ? (
            <button
              className="btn btn-success"
              style={{
                background: '#11ab87',
                width: 71,
                border: 'none',
                boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.35)',
              }}
              onClick={() => setSideMenu(true)}
            >
              MENU
            </button>
          ) : (
            <button
              className="btn btn-dark"
              style={{
                width: 71,
                border: 'none',
                zIndex: 999,
              }}
              onClick={() => setSideMenu(false)}
            >
              Close
            </button>
          )}
        </div>
      )}

      {sideMenu && (
        <>
          <div
            className="fade-in"
            style={{
              background: 'rgba(0,0,0,0.75)',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 980,
            }}
            onClick={() => setSideMenu(false)}
          ></div>
          <div
            className="slide-right"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '75%',
              height: '100%',
              maxWidth: 380,
              zIndex: 999,
              background: '#fff',
              // padding: 20,
              boxShadow: '0px 0px 10px -5px rgba(0,0,0,0.35)',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                background: '#fff',
                padding: '15px 10px',
                position: 'relative',
              }}
            >
              <nav
                className="navbar navbar-expand-lg"
                // style={{ color: '#333', background: '#11ab87' }}
                style={{
                  color: '#333',
                  background: '#fff',
                }}
              >
                <div className="" id="navbarLinks">
                  <ul style={{ marginBottom: 10, padding: 0 }}>
                    {menus.map((menu) => (
                      <li>
                        <a
                          className="nav-link dropdown-toggle"
                          // href={menu.path}
                          // id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          // style={{ color: '#fff', margin: 0 }}
                          style={{ color: '#11ab87', margin: 0, padding: 0 }}
                        >
                          {menu.title}
                        </a>
                        <ul
                          style={{
                            textAlign: 'left',
                            paddingLeft: 0,
                            padding: 0,
                          }}
                        >
                          {menu.submenus.map((submenu) => (
                            <li
                              style={{
                                textAlign: 'left',
                                padding: '12px 18px',
                                lineHeight: 1.2,
                              }}
                              onClick={() => navigate(submenu.path)}
                            >
                              <a>{submenu.title}</a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </>
      )}

      {/* === === */}
      <nav
        className="navbar navbar-expand-lg navbar-dark"
        {...(width < 993 && {
          style: {
            padding: 0,
          },
        })}
      >
        <div
          className="container"
          {...(width >= 993 && {
            onMouseOver: () => setMenuOpen(true),
            onMouseOut: () => setMenuOpen(false),
          })}
        >
          <div className="collapse navbar-collapse" id="navbarLinks">
            <ul className="navbar-nav">
              {/* Menu list */}
              {menus.map((menu) => {
                return (
                  <>
                    {menu.submenus.length < 1 ? (
                      // 단일 메뉴
                      <li className="nav-item active" key={menu.id}>
                        <a
                          className="nav-link"
                          onClick={() => navigate(menu.path)}
                        >
                          {menu.title}
                        </a>
                      </li>
                    ) : (
                      // 확장메뉴1
                      <li className="nav-item dropdown @@blogs" key={menu.id}>
                        <a
                          className="nav-link dropdown-toggle"
                          href={menu.path}
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {menu.title}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {/* {menu.submenus.map((submenu) => (
                            <li key={submenu.id}>
                              <a
                                className="dropdown-item @@blogDetails"
                                // href={submenu.path}
                                onClick={() => navigate(submenu.path)}
                              >
                                {submenu.title}
                              </a>
                            </li>
                          ))} */}
                        </ul>
                      </li>
                    )}
                  </>
                );
              })}

              {/* <li className="nav-item active">
                <a className="nav-link" href="index.html">
                  Home
                </a>
              </li>
              <li className="nav-item @@about">
                <a className="nav-link" href="about.html">
                  About
                </a>
              </li>
              <li className="nav-item @@service">
                <a className="nav-link" href="service.html">
                  Service
                </a>
              </li>
              <li className="nav-item @@gallery">
                <a className="nav-link" href="gallery.html">
                  Gallery
                </a>
              </li>
              <li className="nav-item @@team">
                <a className="nav-link" href="team.html">
                  Team
                </a>
              </li>
              <li className="nav-item @@appointment">
                <a className="nav-link" href="appointment.html">
                  Appointment
                </a>
              </li>
              <li className="nav-item dropdown @@blogs">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Blogs
                </a>

                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item @@blog" href="blog.html">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item @@blogDetails"
                      href="blog-details.html"
                    >
                      Blog Details
                    </a>
                  </li>
                  <li className="dropdown dropdown-submenu dropright">
                    <a
                      className="dropdown-item dropdown-toggle"
                      href="#!"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sub Menu
                    </a>

                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li>
                        <a className="dropdown-item" href="index.html">
                          Submenu 01
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="index.html">
                          Submenu 02
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item @@contact">
                <a className="nav-link" href="contact.html">
                  Contact
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>

      {menuOpen === true ? (
        <div
          style={{ background: 'red', padding: 0, position: 'relative' }}
          onMouseOver={() => setMenuOpen(true)}
          onMouseOut={() => setMenuOpen(false)}
        >
          <div
            style={{
              // background: '#fff',
              background: 'blue',
              boxShadow: '0px 5px 2px -3px rgba(0,0,0,0.07)',
              // padding: 10,
              position: 'absolute',
              width: '100%',
              minHeight: 50,
              top: -70,
              zIndex: 990,
            }}
          >
            <nav
              className="navbar navbar-expand-lg"
              // style={{ color: '#333', background: '#11ab87' }}
              style={{
                color: '#333',
                background: '#fff',
                borderTop: '1px solid #11ab87',
                borderBottom: '1px solid #11ab87',
              }}
            >
              <div className="container">
                <div className="collapse navbar-collapse" id="navbarLinks">
                  <ul className="navbar-nav" style={{ marginBottom: 10 }}>
                    {menus.map((menu) => (
                      <li className="nav-item dropdown @@blogs">
                        <a
                          className="nav-link dropdown-toggle"
                          // href={menu.path}
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          // style={{ color: '#fff', margin: 0 }}
                          style={{ color: '#11ab87', margin: 0, padding: 0 }}
                        >
                          {menu.title}
                        </a>
                        <ul
                          style={{
                            textAlign: 'left',
                            paddingLeft: 0,
                            padding: 0,
                          }}
                        >
                          {menu.submenus.map((submenu) => (
                            <li
                              style={{
                                textAlign: 'left',
                                padding: '12px 18px',
                                lineHeight: 1.2,
                              }}
                              onClick={() => navigate(submenu.path)}
                            >
                              <a>{submenu.title}</a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HeaderNav;
