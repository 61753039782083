import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getCookie } from 'utils';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';
import { BlogList, BlogDetail } from 'components/BlogView';
import styled from 'styled-components';

const {
  intl: { txt: lang },
} = globalObj;

const IntroContainer = styled.div`
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: #FFF5EE; // Adjust background color as needed
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); // Updated shadow
`;

const IntroHeading = styled.h2`
  color: #333; // Adjust text color as needed
`;

const IntroText = styled.p`
  color: #666; // Adjust text color as needed
`;

const PageIntroduction = ({ language }) => {
  const introText = {
    EN: {
      heading: "Saha District News Updates",
      text: "Stay up-to-date with the latest news and insights from the Saha District"
    },
    KR: {
      heading: "사하구 뉴스 업데이트",
      text: "사하구의 최신 뉴스와 인사이트를 확인하세요."
    }
  };

  const currentLang = introText[language] || introText.EN;

  return (
    <IntroContainer>
      <IntroHeading>{currentLang.heading}</IntroHeading>
      <IntroText>{currentLang.text}</IntroText>
    </IntroContainer>
  );
};

const PageIndex = () => {
  const language = getCookie('country') || 'EN'; // Default to English if no cookie is found
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { postId } = useParams();
  const [detail, setDetail] = useState(null);

  const handleChooseData = (item) => {
    setDetail(item);
    navigate(`${pathname}/${item.id}`);
  };

  return (
    <>
      <PageTitle
        title={lang['saha_district_bulletin']}
        breadcrumb={[lang['saha_updates'], lang['saha_district_bulletin']]}
        backgroundImage={'resources/SahaDistrictBulletinMainPic.jpg'}
        backgroundPosition={'center 55%'}
      />
      <PageIntroduction language={language} />
      {!postId ? (
        <BlogList blogType="news" onChooseData={handleChooseData} />
      ) : (
        <BlogDetail data={detail} blogType="news" postId={postId} />
      )}
    </>
  );
};

export default PageIndex;
