import React, { useState } from 'react';
import { getCookie } from 'utils';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';
import { BlogList, BlogDetail } from 'components/BlogView';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';


const {
  intl: { txt: lang },
} = globalObj;

const IntroContainer = styled.div`
padding: 20px;
border-radius: 10px;
text-align: center;
background-color: #FAF0E6	; // Adjust background color as needed
box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); // Updated shadow
`;

const IntroHeading = styled.h2`
  color: #333;
`;

const IntroText = styled.p`
  color: #666;
`;

const PageIntroduction = ({ language }) => {
  const introText = {
    EN: {
      heading: "SAHA, Where Culture Meets Convenience",
      text: "Explore Saha District's dynamic lifestyle, rich cultural fabric, and extensive transport network. An ideal destination for both residents and visitors seeking an enriching experience."
    },
    KR: {
      heading: "SAHA, 더 나은 미래, 새로운 사하",
      text: "끊임없는 발전과 변화의 중심지로, 과거와 미래가 조화롭게 어우러지며, 문화와 혁신이 공존하는 특별한 장소를 경험해보세요"
    }
  };

  const currentLang = introText[language] || introText.EN;

  return (
    <IntroContainer>
      <IntroHeading>{currentLang.heading}</IntroHeading>
      <IntroText>{currentLang.text}</IntroText>
    </IntroContainer>
  );
};

const Transportation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { postId } = useParams();
  const [detail, setDetail] = useState(null);

  const handleChooseData = (item) => {
    setDetail(item);
    navigate(`${pathname}/${item.id}`);
  };

  return (
    <>
      <PageTitle
        title={lang['lifestyle_transportation']}
        breadcrumb={[lang['saha_life'], lang['lifestyle_transportation']]}
        backgroundImage={'resources/saha_life/2_LIFESTYLE_TRANSPORTATION_Pic1.JPG'}
        backgroundPosition={'center 65%'}
      />
      <PageIntroduction language={getCookie('country') || 'EN'} />
      {!postId ? (
        <BlogList blogType="transportation" onChooseData={handleChooseData} />
      ) : (
        <BlogDetail data={detail} blogType="transportation" postId={postId} />
      )}
    </>
  );
};

export default Transportation;
