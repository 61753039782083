import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import styled from 'styled-components';



// Create a custom icon with a number label

function createNumberedIcon(number) {
  const svgString = `
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="14" fill="#007bff" />
      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#ffffff" font-size="12">
        ${number}
      </text>
    </svg>
  `;

  return L.divIcon({
    className: 'custom-icon',
    html: svgString,
    iconSize: [32, 32],
  });
}


const MarkerNumber = styled.div`
  background-color: #007bff; /* Set the background color for the circle */
  color: #ffffff; /* Set the text color */
  width: 32px; /* Set the width of the circle */
  height: 32px; /* Set the height of the circle */
  border-radius: 50%; /* Make it a circle using border-radius */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const styles = {
  cardStyle: {
    // padding: '25px 20px',
    padding: 1,
    boxShadow: '1px 1px 8px 0px rgba(0,0,0,0.25)',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: 15,
  },
};


// Define the onEachFeature function
const onEachFeature = (feature, layer) => {
  // Your code for handling GeoJSON features goes here
  // For example, you can add a popup to the layer
  if (feature.properties && feature.properties.name) {
    layer.bindPopup(feature.properties.name);
  }
};

const kicLocations = [
  {
    name: '펜타플렉스 1차',
    latitude: 35.089458,
    longitude: 128.968583,
    image: '1_KIC_Overview_pic1-1.png',
  },
  {
    name: '스마트 T타워',
    latitude:  35.086057,
    longitude: 128.971536,
    image: '1_KIC_Overview_pic1-2.png',
  },
  {
    name: '점프타워',
    latitude: 35.090576,
    longitude: 128.958369,
    image: '1_KICOverview_pic3.jpeg',
  },
  {
    name: '리드원',
    latitude: 35.090871,
    longitude: 128.962775,
    image: '1_KICOverview_pic5.jpeg',
  },
  {
    name: 'SK V1',
    latitude: 35.087268,
    longitude: 128.969886,
    image: '1_KICOverview_pic6.jpeg',
  },
  {
    name: '펜타플렉스 메트로',
    latitude: 35.089268,
    longitude: 128.973845,
    image: '1_KICOverview_pic6-1.png',
  },
  {
    name: '점프타워2차',
    latitude: 35.089720,
    longitude: 128.966602,
    image: '1_KICOverview_pic77.png',
  },
  {
    name: '스카이 더 테라스',
    latitude: 35.077802,
    longitude: 128.975444,
    image: '1_KICOverview_pic88.png',
  },
  {
    name: '신평동 지식산업단지 건축 예정',
    latitude: 35.095152,
    longitude: 128.957471,
    image: '1_KICOverview_pic99.png',
  },
  {
    name: '장림동 지식산업단지 건축 예정',
    latitude: 35.084831,
    longitude: 128.973344,
    image: '1_KICOverview_pic1010.png',
  },
  {
    name: '장림동 지식산업단지 건축예정',
    latitude: 35.072409,
    longitude: 128.975634,
    image: '1_KICOverview_pic1111.png',
  },
  {
    name: '신평동 지식산업단지 건축 예정',
    latitude: 35.093056,
    longitude: 128.964365,
    image: '1_KICOverview_pic1212.png',
  },
  {
    name: '신평동 지식산업단지 건축 예정',
    latitude: 35.095656,
    longitude: 128.959762,
    image: '1_KICOverview_pic1313.png',
  },
  {
    name: '장림동 지식산업단지 건축 예정',
    latitude: 35.071652,
    longitude: 128.978198,
    image: '1_KICOverview_pic1414.png',
  },
];



/**
 * [Component] KICOverview
 * --
 */
const Ko = ({ list }) => {
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;

      const mapMarkers = kicLocations.map((location, index) => {
        const marker = L.marker([location.latitude, location.longitude], {
          icon: createNumberedIcon(index + 1),
        });

        marker.bindPopup(`<h3>${location.name}</h3><img src="resources/KIC/${location.image}" alt="${location.name}" width="200" />`);

        // Add a click event listener to open the popup
        marker.on('click', () => {
          console.log('Marker clicked'); // Add this line

          marker.openPopup();
        });

        return marker;
      });

      // Add the markers to the map and store them in the state
      mapMarkers.forEach(marker => marker.addTo(map));
      setMarkers(mapMarkers);
    }
  }, [mapRef]);

const handleImageClick = (index) => {
  if (mapRef.current && index >= 0 && index < kicLocations.length) {
    const location = kicLocations[index];
    mapRef.current.flyTo([location.latitude, location.longitude], 14);

    // Scroll to the map container
    const mapContainer = mapRef.current.getContainer();
    window.scrollTo({
      top: mapContainer.offsetTop,
      behavior: 'smooth',
    });
    // Programmatically trigger a click event on the marker to open the popup
    const marker = markers[index];
    if (marker) {
      marker.openPopup();
    }
  } else {
    console.error('Map or markers not available.');
  }
};
    
    
    // Define the useEffect to fit the map to the bounds of all markers
    useEffect(() => {
      // Calculate the bounds of all markers
      const bounds = L.latLngBounds(kicLocations.map(location => [location.latitude, location.longitude]));
  
      if (mapRef.current) {
        // Fit the map to the bounds of all markers
        mapRef.current.fitBounds(bounds);
      }
    }, []);
  
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>사하구 신평-장림 산업단지의 혁신 원동력</h2>
              </div>
              <div class="story-content">
                <h4 class="tagline">"사하구 지식산업센터(KIC)의 투자 기회"</h4>
                <p className="align-justify">
                  사하구에는 제조업의 중심지이자 부산 경제 성장의 주역인
                  신평-장림산업단지가 자리 잡고 있습니다. 지역 경제를 더욱
                  활성화하기 위해 새로운 지식산업센터(KIC)를 건설하고 있습니다.
                  이 단지들은 제조, 정보통신, 연구 개발, 지식 기반 스타트업 등
                  다양한 부문에 서비스를 제공하는 복합용도 시설을 제공합니다.
                  주요 위치, 우수한 교통 연결, 완비된 시설 덕분에 외국인
                  투자자와 공장 소유주에게 이상적인 투자처가 될 수 있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-md-12"
              style={{
                // background: `url("resources/KIC/0011.jpeg")`,
                borderRadius: 20,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            >
              <img
                src="resources/KIC/0011.jpeg"
                width={'95%'}
                style={{
                  borderRadius: 20,
                  border: '0px solid #dadada',
                }}
                alt=""
              />
            </div>

            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h2>지식산업센터(KIC)란 무엇인가요?</h2>
              </div>
              <div class="story-content">
                <p className="align-justify">
                  지식산업센터는 제조, 정보통신, 연구 개발, 지식 기반 스타트업
                  등 다양한 분야의 비즈니스가 입주할 수 있도록 설계된 최첨단
                  인프라입니다. 복합용도 시설을 갖춘 이 센터는 비즈니스가
                  번창하고 혁신할 수 있는 완벽한 환경을 제공합니다. 사무 공간,
                  생산 시설, 연구실, 주거 공간 등을 갖추고 있어 창의성과
                  생산성을 키울 수 있는 일과 생활이 통합된 생태계를 조성합니다.
                  사하구 KIC는 뛰어난 입지, 우수한 교통망, 완비된 시설을 갖추고
                  있어 외국인 투자자와 공장 소유주에게 이상적인 투자 기회입니다.
                  <br />
                  <br />
                  사하구 지식산업단지는 뛰어난 입지, 우수한 교통망, 완비된
                  시설을 갖추고 있어 외국인 투자자와 공장 소유주에게 이상적인
                  투자 기회입니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* row */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>신평-장림 산업단지에 건설 중인 지식산업센터 현황</h2>
              </div>
            </div>
            {/* #1 */}

            {/* #2 */}
            {list.map((item, index) => (
              <div
                className="col-lg-4 col-md-6"
                key={`kicoverview_${index}`}
                style={{ marginBottom: 25, cursor: 'pointer' }} // Add cursor style
                onClick={() => handleImageClick(index)} // Add onClick to handle image click
              >
                <div style={styles.cardStyle}>
                  <div
                    style={{
                      // backgroundImage: `url("resources/discover_saha/${item.image}")`,
                      backgroundImage: `url("resources/KIC/${item.image}")`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      height: 250,
                      borderRadius: 15,
                    }}
                  ></div>
                  <div className="content-block" style={{ padding: 20 }}>
                    <h5>{item.title_en}</h5>
                    <hr />
                    <p style={{ margin: 0 }}>{item.comment_en}</p>
                  </div>
                </div>
              </div>
            ))}
            {/* #2 */}
          </div>
          {/* row */}
        </div>
      </section>

      {/* Leaflet Map */}
      <MapContainer center={[35.0892, 128.9686]} zoom={14} style={{ height: '600px', width: '100%' }} ref={mapRef}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {kicLocations.map((location, index) => (
          <Marker
            key={index}
            position={[location.latitude, location.longitude]}
            icon={createNumberedIcon(index + 1)} // Use the custom icon with numbers
            
          >
            <Popup>
              <div>
                <h4>{location.name}</h4>
                <img src={`resources/KIC/${location.image}`} alt={location.name} width="200" />
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/KIC/1_KICOverview_pic9.jpg"
                width={'95%'}
                style={{
                  borderRadius: 20,
                  border: '0px solid #dadada',
                }}
                alt=""
              />
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="content-block">
                <h2>글로벌 인재 및 기업 유치</h2>
              </div>
              <div class="story-content">
                <p className="align-justify">
                  우리는 이 기회에 참여할 외국인 투자자와 공장 소유주를
                  적극적으로 유치하고 있습니다. 사하구의 지식산업센터에서 사업을
                  시작하면 인프라의 이점을 누릴 수 있을 뿐만 아니라 부산
                  사하구의 활기찬 지식 기반 경제에 기여할 수 있습니다.
                  <br />
                  <br />
                  지식산업센터에 대한 문의 사항이나 추가 정보가 필요하시면
                  문의하시기 바랍니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12" style={{ marginBottom: 30 }}>
            </div>
            {list.map((item, index) => (
              <div
                className="col-lg-6 col-md-12"
                key={`kicoverview2_${index}`}
                style={{ marginBottom: 30 }}
              >
                <div
                  style={{
                    ...styles.cardStyle,
                    borderRadius: 10,
                    padding: '25px 25px',
                  }}
                >
                  <div className="row">
                    {/*  */}
                    <div className="col-3">
                      <div
                        style={{
                          width: 110,
                          height: 110,
                          margin: '0 auto',
                          borderRadius: '100%',
                          background: '#f1f1f1',
                          position: 'relative',
                          background: `url("resources/KIC/${item.image}")`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                        }}
                      >
                        <div
                          style={{
                            width: 35,
                            height: 35,
                            margin: '0 auto',
                            borderRadius: '100%',
                            background: '#fff',
                            color: '#cf467f',
                            border: '3px solid #cf467f',
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {index + 1}
                        </div>
                      </div>
                    </div>
                    <div className="col-9">
                      <h4>{item.title}</h4>
                      <div class="story-content">
                        <p className="align-justify">{item.comment}</p>
                        <hr />
                        <p className="align-justify">{item.address}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: '10px 35px' }}
            >
              <div className="content-block">
                <h3>지식산업센터의 일자리 창출과 경제 활성화</h3>
                <hr />
              </div>
              <div class="story-content">
                <p className="align-justify">
                  향후 완공 예정인 13개의 지식산업센터에는 총 2,930여 개의
                  기업과 종사자가 입주해 지역 경제에 크게 기여할 것으로
                  예상됩니다. 예를 들어, 연면적 165,648.66㎡ 규모의 SK V1(U1)은
                  완공 시 2,930개의 일자리를 창출할 것으로 예상되며, 연면적
                  93,818.26㎡ 규모의 장림동 488번지 KIC는 1,660개의 일자리를
                  창출할 것으로 예상됩니다. 프로젝트가 진행됨에 따라 더 많은
                  업데이트를 기대해주세요.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: '10px 35px' }}
            >
              <div className="content-block">
                <h3>미래를 짓다: 혁신과 성장의 허브 조성</h3>
                <hr />
              </div>
              <div class="story-content">
                <p className="align-justify">
                  사하구청에서는 지식산업센터의 발전을 지원하기 위해 최선을
                  다하고 있습니다. 우리의 비전은 역동적인 혁신과 성장의 허브를
                  조성하여 입주 기업의 번영을 보장하는 데 중점을 두고 있습니다.
                  기존 단지의 확장을 지원하는 것부터 새로운 최첨단 시설의 건설을
                  촉진하는 것까지, 우리의 이니셔티브는 미래지향적이고 커뮤니티에
                  초점을 맞추고 있습니다. 개별 비즈니스의 성장을 촉진할 뿐만
                  아니라 지역과 커뮤니티의 전반적인 활력에도 기여할 수 있는
                  환경을 조성하는 것을 목표로 하고 있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ko;
