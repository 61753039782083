/**
 *
 *
 */

import React, { useState } from 'react';
import { getCookie, setCookie } from 'utils';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Loading } from 'components';

/**
 * [Component] Login
 * --
 */
const Login = () => {
  /* ===== State ===== */
  const language = getCookie('country');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [lod, setLod] = useState(false);
  const [data, setData] = useState({
    account: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLod(true);
    const { account, password } = data;
    if (
      account !== process.env.REACT_APP_ADMIN_ACCOUNT ||
      password !== process.env.REACT_APP_ADMIN_PASSWORD
    ) {
      alert('계정정보를 확인해주세요');
      setLod(false);
      return;
    }
    setCookie('NEWSAHA_ACCESS_TOKEN', 'newsaha');
    setLod(false);
    navigate('/', { replacement: true });
  };

  /* ===== Render ===== */
  return (
    <>
      <Loading visible={lod} />
      <div
        className="container"
        style={{
          maxWidth: 480,
          marginTop: 50,
          marginBottom: 150,
        }}
      >
        <h2>Login</h2>
        <hr />
        <form action="" onSubmit={handleLogin}>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Account
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="계정을 입력해주세요"
              name={'account'}
              value={data.account}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              placeholder="입력해주세요"
              name={'password'}
              value={data.password}
              onChange={handleChange}
            />
          </div>
          <br />

          <div>
            <button className="btn btn-success" style={{ width: '100%' }}>
              Login
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
