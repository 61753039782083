/**
 *
 *
 */

import React from 'react';

const styles = {
  articleTitle: {
    color: '#333',
    marginBottom: 15,
  },
  articleWarpper: {
    textAlign: 'center',
  },
  articleImage: {
    width: '100%',
    height: '100%',
    maxHeight: 270,
    minHeight: 225,
    margin: '0 auto',
    backgroundSize: 'cover',
    // backgroundSize: 'cover 100%',
    border: '1px solid #dadada',
    borderRadius: 20,
  },
};

/**
 * [Component] Sample
 * --
 */
const En = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12" style={{ textAlign: 'center' }}>
              {/* 이미지 */}
              <div
                style={{
                  width: '99%',
                  height: '90%',
                  minHeight: 270,
                  margin: '0 auto',
                  backgroundSize: 'cover',
                  border: '1px solid #dadada',
                  borderRadius: 20,
                  backgroundImage: `url("resources/WhySaha/1_Saha_Vision_pic1.JPG")`,
                }}
              ></div>
              <p className="italic">
                Scenic Views of Dangni-dong and Hadan-dong
              </p>
              <br />
              <br />
              <br />
            </div>

            {/* 내용 */}
            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h2>
                  The Ensemble City, SAHA
                  <br />
                  <small style={{ fontSize: '0.7em', lineHeight: 0.1 }}>
                    "Creating harmony between the serenity of nature and the
                    vibrant rhythm of urban life."
                  </small>
                </h2>

                <p style={{ textAlign: 'justify' }}>
                  Welcome to Saha District, a unique blend of bustling markets,
                  peaceful parks, serene seascapes, dynamic ports, and the
                  harmonious coexistence of mountains and rivers. As 'The
                  Ensemble City,' we are devoted to crafting a living experience
                  that fosters joy, fulfillment, and an exceptional balance
                  between the calming rhythm of nature and the energizing pulse
                  of urban life. Saha is more than a district—it's an ensemble
                  of life's finest moments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* === 타이틀 === */}
            <div className="col-md-12">
              <div className="content-block">
                <h2>THE VISIONS OF SAHA</h2>
                <h4>"Our roadmap to a harmonious future."</h4>
                <p>
                  As we step into the future, our plans for Saha are guided by
                  seven distinctive visions. Each of these represents a crucial
                  aspect of Saha's evolution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div
          className="container"
          style={{ paddingRight: 26, paddingLeft: 26 }}
        >
          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-5" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic2.jpeg")`,
                }}
              ></div>
              <p className="italic">Dadae Marine City</p>
              <br />
              <br />
              <br />
            </div>
            {/* #1 */}
            <div className="col-lg-7">
              <h3 style={styles.articleTitle}>
                1.Enhancing Life's Value through a Premium Residential City
              </h3>
              <hr />
              <p>
                <h5>"Creating homes where comfort, convenience"</h5>
                and elegance converge." We are dedicated to transforming Saha
                into a premium residential city that enhances living standards
                and fosters a sense of pride among our residents.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-7">
              <h3 style={styles.articleTitle}>2. A Welfare City for All</h3>
              <hr />
              <p>
                <h5>
                  "Caring for our citizens, addressing their unique needs"
                </h5>
                Our commitment to holistic well-being enables us to provide
                comprehensive support, tailored to the specific needs of each
                resident.
              </p>
            </div>
            {/* #1 */}
            <div className="col-lg-5" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_Pic9.jpg")`,
                }}
              ></div>
              <p>
                {
                  '<Empowering the Next Generation: Launch Ceremony of Child Participation Organization >'
                }
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-5" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic4.png")`,
                }}
              ></div>
              <p className="italic">Knowledge Industry Centers(KICs)</p>
              <br />
              <br />
              <br />
            </div>
            {/* #1 */}
            <div className="col-lg-7">
              <h3 style={styles.articleTitle}>
                3. Together, A Smart Economic City
              </h3>
              <hr />
              <h5>
                "Fusing technology with community for economic growth." We aim
                to stimulate economic growth through the integration of smart
                technology, building a thriving and inclusive community for all"
              </h5>
              <p>
                We aim to stimulate economic growth through the integration of
                smart technology, building a thriving and inclusive community
                for all.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-7">
              <h3 style={styles.articleTitle}>4. A Transportation Hub City</h3>
              <hr />
              <h5>"Connecting Saha to the world."</h5>
              <br />
              <p>
                We are transforming Saha into a strategic transportation hub,
                enhancing connections with Gadeok International Airport and
                expanding our road networks and urban railways.
              </p>
            </div>
            {/* #1 */}
            <div className="col-lg-5" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic5.png")`,
                }}
              ></div>
              <p className="italic">Busan-type Express Railway, or BuTX </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-5" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic3.jpeg")`,
                }}
              ></div>
              <p className="italic">
                {'<Specialized elderly sports facility>'}
              </p>
              <br />
              <br />
              <br />
            </div>
            {/* #1 */}
            <div className="col-lg-7">
              <h3 style={styles.articleTitle}>
                5. A Healing Tourist City Where Nature and Culture Harmonize
              </h3>
              <hr />
              <h5>
                "Offering immersive experiences in our cultural heritage and
                natural landscapes."
              </h5>
              <br />
              <p>
                Our commitment to sustainable tourism offers visitors a unique
                journey through Saha's rich cultural heritage and stunning
                natural landscapes.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-7">
              <h3 style={styles.articleTitle}>
                6. A Pleasant and Livable Green Safe City
              </h3>
              <hr />
              <h5>
                "Creating a safe, sustainable, and comfortable environment."
              </h5>
              <br />
              <p>
                We prioritize safety, sustainability, and comfort in our mission
                to build a green city that offers an ideal living environment.
              </p>
            </div>
            {/* #1 */}
            <div className="col-lg-5" style={styles.articleWarpper}>
              <div
                style={{
                  ...styles.articleImage,
                  backgroundImage: `url("resources/WhySaha/1_SahaVision_pic7.jpg")`,
                }}
              ></div>
              <p className="italic">Eulsukdo Migratory Bird Park</p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* === row === */}

          {/* === row === */}

          <br />
          <br />
          {/* === row === */}
        </div>
      </section>
    </>
  );
};

export default En;
