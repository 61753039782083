/**
 * 다국어 : 한국어
 *
 */

export default {
  operation_comment: '운영시간 : 월요일~금요일(09:00~18:00)',
  /* Menus */
  why_saha: 'Why Saha',
  saha_vision: '사하구의 비전',
  development_plan: '발전계획',
  history_culture: '매력과 강점',

  discover_saha: 'Saha소개',
  mayors_greetings: '구청장 인사말',
  organization_structure: '조직구조',

  kics: 'KIC지식산업센터',
  kic_overview: '지식산업센터 개요',
  investment_incentives: '투자혜택',
  kics_progress: 'KIC동향',

  strategic_business_division: '전략사업과',
  development_overview: '전략사업과 개요',
  innovation_tech: '혁신&기술',
  rnd_support: 'R&D지원',

  saha_life: 'Saha라이프',
  district_housing: '지역&주거',
  lifestyle_transportation: '생활&교통',
  saha_edu: '사하글로벌교육',

  saha_updates: 'Saha뉴스',
  saha_district_bulletin: '사하 뉴스',
  announcements: '공지사항',
  press_release: '보도자료',
  newsletters: '뉴스레터',
  downloads: '다운로드 센터',

  support: '커뮤니티',
  location_info: '위치&세부정보',
  inquiry_form: '문의양식',
  helpful_links: '유용한 링크',
  // _: '',
};
