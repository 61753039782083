import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from 'firebase.config';
import { collection, getDocs } from 'firebase/firestore';
import moment from 'moment';
import styled from 'styled-components';

// Styled Components

const CardDiv = styled.div`
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;

  h5 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700; 
    minHeight: '40px';
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
  }

  &:hover {
    background-color: #f0f4f8;
  }

  &:not(:hover) {
    background-color: #fff;
  }
`;

const CenteredTextDiv = styled.div`
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.3s ease;
  &:hover {
    h3 {
      color: #11ab87;
      opacity: 0.8;
    }
  }
  h3 {
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
  }
`;

const StyledButton = styled.button`
  background-color: #11ab87;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }

  &:active {
    background-color: #077a57;
    transform: scale(1);
  }
`;

const ThumbnailDiv = styled.div`
  width: 100%;
  height: 100%;
  max-height: 200px;
  min-height: 180px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: ${props => props.thumbnail ? `url(${props.thumbnail.replace(/['"]+/g, '')}) center/cover no-repeat` : `url('default_logo.png')`};
  opacity: ${props => props.thumbnail ? 1 : 0.35};
`;

const Tag = styled.div`
  padding: 0px 10px;
  margin-bottom: 3.5px;
  margin-right: 2.5px;
  border-radius: 15px;
  background: #fafafa;
  border: 1px solid #ddd;
`;


// Main Component
const ContentPreview = ({
  language,
  firebasePath,
  navigationPath,
  titleEn,
  titleKr,
  descriptionEn,
  descriptionKr
}) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fdDocument = collection(db, ...firebasePath.split('/'));
        const result = await getDocs(fdDocument);
        
        const rr = result.docs.map(item => ({
          id: item.id,
          ...item.data()
        }));

        const sortedList = rr.sort((a, b) => moment(b.date).unix() - moment(a.date).unix());
        setList(sortedList);
      } catch (e) {
        console.error('Error fetching data:', e);
      }
    };

    fetchData();
  }, [firebasePath]);

  const renderContentCard = (item) => {
    const content = item.content.replaceAll(/<[^>]+>.*?/g, '');
    const truncatedContent = content.substring(0, 200) + (content.length > 200 ? "..." : "");
    const thumbnailRaw = item.content.match(/src=(["'].*?["'])/g, '');
    const thumbnail = thumbnailRaw ? thumbnailRaw[0].split('src=')[1] : null;

    return (
      <CardDiv
        className="col-lg-4 col-md-6 col-sm-6 col-xs-6"
        key={item.id}
        onClick={() => navigate(`${navigationPath}/${item.id}`)}
      >
        <ThumbnailDiv thumbnail={thumbnail}></ThumbnailDiv>
        <div style={{ padding: '15px' }}>
          <h5 style={{ minHeight: '40px' }}>{item.title}</h5>
          <p style={{ height: '55px', overflow: 'hidden' }}>{truncatedContent}</p>
          <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '5px' }}>
            {item.tags && item.tags.map(tag => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </div>
          <div style={{ textAlign: 'right', color: '#a1a1a1', fontSize: '0.8em' }}>{moment(item.date).format('YYYY-MM-DD')}</div>
        </div>
      </CardDiv>
    );
  };

  return (
    <section className="feature-section section bg-gray" style={{ padding: '50px 0' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <CenteredTextDiv onClick={() => navigate(navigationPath)}>
              <h3>
                {language !== 'KR' ? titleEn : titleKr}
              </h3>
              <p className="mb-0">
                {language !== 'KR' ? descriptionEn : descriptionKr}
              </p>
            </CenteredTextDiv>
  
            <div className="row">
              {list.length === 0 ? (
                <div className="col-12">
                  <h3 style={{ textAlign: 'center', margin: '40px 0', color: '#a1a1a1' }}>
                    No data
                  </h3>
                </div>
              ) : (
                list.slice(0, 6).map(item => renderContentCard(item))
              )}
            </div>
  
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <StyledButton onClick={() => navigate(navigationPath)}>
                View All
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentPreview;
