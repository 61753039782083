/**
 *
 *
 */

import React from 'react';

/**
 * [Component] DivisionOverview
 * --
 */
const En = () => {
  /* ===== State ===== */
  const members = [
    {
      position: 'Director',
      name: 'Byeon Yeong-tae',
      contact: '051-220-4310',
      email: 't8588012@korea.kr',
      responsibilities: '',
    },
    {
      position: 'Supervisor',
      name: 'Jeong kyu-seop',
      contact: '051-240-4313',
      email: 'jks4166@korea.kr',
      responsibilities: 'Head of the Future Initiatives Team',
    },
    {
      position: 'Officer',
      name: 'Yu Hyun-seok ',
      contact: '051-220-4314',
      email: 'kalinhand78@korea.kr',
      responsibilities:
        'Reviewing initiatives and specific directives received from the mayor',
    },
    {
      position: 'Officer',
      name: 'Hong Yong-wook',
      contact: '051-220-4312',
      email: 'kora2116@korea.kr',
      responsibilities: 'Long-term Saha development plans',
    },
    {
      position: 'Officer',
      name: 'Jeong Min-gyu',
      contact: '051-220-4315',
      email: 'jmg89@korea.kr',
      responsibilities: 'The development of Dadaepo coast',
    },
    {
      position: 'Officer',
      name: 'Kwon Da-hae',
      contact: '051-220-4316',
      email: 'gooneoda@korea.kr',
      responsibilities: 'Tourism development project',
    },
    {
      position: 'Officer',
      name: 'Jeong Ye-rin',
      contact: '051-220-4311',
      email: 'rin13561@korea.kr',
      responsibilities: 'Secretarial and accounting duties',
    },
    {
      position: 'Supervisor',
      name: 'Lee Keun-yeong',
      contact: '051-220-5023',
      email: 'icib123@korea.kr',
      responsibilities: 'Head of the Business Expansion Team',
    },
    {
      position: 'Officer',
      name: 'Seol Jung-hee',
      contact: '051-220-5024',
      email: 'zlflfcm82@korea.kr',
      responsibilities:
        'Managing industrial complexes and enhancing business regulations',
    },
    {
      position: 'Officer',
      name: 'Kim So-yeon',
      contact: '051-220-5022',
      email: 'kkss12345@korea.kr',
      responsibilities:
        'Renewable energy generation projects, hydrogen fuel supply facilities',
    },
    {
      position: 'Officer',
      name: 'Jeong Seol-ah',
      contact: '051-220-5026',
      email: 'reuna16@korea.kr',
      responsibilities:
        'Knowledge Industry Centers, renewable energy generation project',
    },
    {
      position: 'Officer',
      name: 'Jeon Ga-yeong',
      contact: '051-220-5025',
      email: 'jgy1106@korea.kr',
      responsibilities: 'Facilitating industrial-academia cooperation',
    },
  ];

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>Division Overview</h2>

                <p className="align-justify">
                  Welcome to the page of the Strategic Business Division. Our
                  dedicated team diligently works towards systematically shaping
                  the long-term development blueprint for the Saha district and
                  facilitating an array of regional development projects. We are
                  engaged in devising innovative solutions to streamline
                  regulations, with the goal of enticing business investments
                  and fostering an environment conducive to enterprise growth.
                  Our primary mission is to lay a robust foundation for the
                  future growth of Saha, as we strive towards constructing a
                  brighter future and fostering a reinvigorated Saha. Thank you
                  for your interest and support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/strategic_business_division/1_DivisionOverview1.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>What We Do</h2>
              </div>
              <h6>
                1. Formulating holistic long-term development blueprints
                <hr />
                2. Leading the execution of Saha's forward-thinking development
                programs
                <hr />
                3. Pioneering the expansion of tourism via strategic development
                initiatives
                <hr />
                4. Streamlining regulatory protocols in relation to Knowledge
                Industry Centers and commercial enterprises
                <hr />
                5. Administering the suite of renewable energy projects and
                nurturing emergent growth arenas, inclusive of avant-garde
                initiatives
                <hr />
                6. Supervising the amalgamation of academic pursuits with
                industrial enterprises, along with other accountabilities.
              </h6>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>Strategic Business Division</h2>
              </div>
              <h5>
                Email: admin@newsaha.net
                <hr />
                Phone number: 051-220-4311
                <hr />
                Fax: 051-220-4319
              </h5>
            </div>
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/strategic_business_division/1_DivisionOverview3.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="content-block">
            <h2>Organizational Chart</h2>
          </div>
          {/* Row */}
          <div
            style={{
              margin: '15px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                background: '#339e0d',
                padding: 15,
                width: '100%',
                maxWidth: 660,
                height: 100,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                border: '2px solid #287d0a',
              }}
            >
              <h4>Strategic Business Division</h4>
            </div>
          </div>
          {/* Row */}
          {/* Row */}
          <div
            style={{
              margin: '15px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: 320,
                margin: '0 10px',
              }}
            >
              <div
                style={{
                  background: '#fff',
                  padding: 15,
                  width: '100%',
                  height: 100,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#287d0a',
                  border: '2px solid #339e0d',
                }}
              >
                <h4>Future Initiatives Team</h4>
              </div>
            </div>

            <div
              style={{
                width: '100%',
                maxWidth: 320,
                margin: '0 10px',
              }}
            >
              <div
                style={{
                  background: '#fff',
                  padding: 15,
                  width: '100%',
                  height: 100,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#287d0a',
                  border: '2px solid #339e0d',
                }}
              >
                <h4>Business Expansion Team</h4>
              </div>
            </div>
          </div>
          {/* Row */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="content-block">
            <h2>
              Team Members
              <small>({members.length})</small>
            </h2>
          </div>
          {/* Row */}
          <div className="row">
            {/* <h6>구성원</h6> */}
            {/* === 구성원 === */}
            {members.map((item) => (
              <div className="col-lg-4 col-md-6" style={{ padding: 10 }}>
                <div
                  className="team-person text-left "
                  style={{
                    padding: '20px 25px',
                    height: 295,
                    background: '#fff',
                    border: '1px solid #f1f1f1',
                    boxShadow: '1px 1px 5px -2px rgba(0,0,0,0.3)',
                  }}
                >
                  <div
                    style={{
                      borderRadius: '100%',
                      width: 55,
                      height: 55,
                      background: '#f1f1f1',
                      marginBottom: 15,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#dbdbdb',
                    }}
                  >
                    <h5>{item.name[0]}</h5>
                  </div>
                  <h5>
                    {item.name} <small>/ {item.position}</small>
                  </h5>
                  <hr style={{ margin: '10px 0' }} />
                  <p style={{ margin: 0 }}>
                    <b>Contact</b>: {item.contact}
                  </p>
                  <p style={{ margin: 0 }}>
                    <b>Email</b>: {item.email}
                  </p>
                  {item.responsibilities && (
                    <p style={{ margin: 0 }}>
                      <b>Responsibilities</b>: {item.responsibilities}
                    </p>
                  )}
                </div>
              </div>
            ))}
            {/* === 구성원 === */}
          </div>
          {/* Row */}
        </div>
      </section>
    </>
  );
};

export default En;
