import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const ItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  text-decoration: none;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow here */

  @media (max-width: 768px) {
    padding-bottom: 20px; /* Adjust padding for mobile */
  }
`;


const HoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
    background-color: #f9f9f9;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  @media (max-width: 768px) {
    text-align: center; /* Center text for mobile */
  }
`;

const ImageBox = styled.div`
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 185px;
  border: 1px solid #e9e9e9;
  border-radius: 15px;
  margin-bottom: 15px;
  background-image: ${(props) => `url(${props.backgroundImage})`};

  @media (max-width: 768px) {
    height: 150px; /* Adjust height for mobile */
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Tags aligned to the left */
`;

const Tag = styled.div`
  padding: 0px 10px;
  margin: 0 2.5px 3.5px 0;
  border-radius: 15px;
  background: #fafafa;
  border: 1px solid #ddd;
  font-size: 10px; /* Reduce font size for tags */

  @media (max-width: 768px) {
    font-size: 9px; /* Adjust font size for mobile */
  }
`;

const ReadMoreButton = styled.div`
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px; /* Adjust margin for mobile */
  }
`;

const BlogTitle = styled.h4`
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  min-height: 40px;
  font-size: 20px; /* Default font size for larger screens */

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for mobile */
  }
`;

const BlogContent = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
`;

const BlogItem = ({ item, index, type = 'default', onChooseData }) => {
  const content = item.content.replaceAll(/<[^>]+>.*?/g, '');
  const thumbnailRaw = item.content.match(/src=(["'].*?["'])/g, '');
  const thumbnail = thumbnailRaw ? thumbnailRaw[0].split('src=')[1] : null;

  return (
    <ItemHolder onClick={(e) => onChooseData(item)} key={`blog-tspt-${index}`}>
      <HoverContainer>
        {thumbnail && <ImageBox backgroundImage={thumbnail}></ImageBox>}
        
        <ContentContainer>
          <BlogTitle>{item.title ? item.title : '-'}</BlogTitle>

          <div>
            <span>{moment(item.date).format('YYYY.MM.DD (HH:mm)')}</span>
            <BlogContent>
              {item.content
                ? `${content.slice(0, 300)}${content.length > 300 ? '...' : ''}`
                : `-`}
            </BlogContent>
          </div>

          <TagsContainer>
            {!item.tags || item.tags.length < 1
              ? null
              : item.tags.map((tagItem, tagIndex) => (
                  <Tag key={tagItem}>{tagItem}</Tag>
                ))}
          </TagsContainer>

          <ReadMoreButton className="link-btn btn-style-one">Read more</ReadMoreButton>
        </ContentContainer>
      </HoverContainer>
    </ItemHolder>
  );
};

export default BlogItem;
