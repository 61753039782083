import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getCookie } from 'utils';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';
import { BlogList, BlogDetail } from 'components/BlogView';
import styled from 'styled-components';

const {
  intl: { txt: lang },
} = globalObj;

const IntroContainer = styled.div`
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: #FFF0F5; // Adjust background color as needed
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); // Updated shadow
`;

const IntroHeading = styled.h2`
  color: #333; // Adjust text color as needed
`;

const IntroText = styled.p`
  color: #666; // Adjust text color as needed
`;

const PageIntroduction = ({ language }) => {
  const introText = {
    EN: {
      heading: "Stay Updated with Saha District's Corporate News",
      text: "Receive newsletters with the latest corporate news and insights from the Saha District."
    },
    KR: {
      heading: "사하구 기업소식 뉴스레터 받기",
      text: "사하구의 기업소식을 뉴스레터로 받아보세요."
    }
  };

  const currentLang = introText[language] || introText.EN; // Default to English if language is not found

  return (
    <IntroContainer>
      <IntroHeading>{currentLang.heading}</IntroHeading>
      <IntroText>{currentLang.text}</IntroText>
    </IntroContainer>
  );
};

const Newsletters = () => {
  const language = getCookie('country') || 'EN'; // Default to English if no cookie is found
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { postId } = useParams();
  const [detail, setDetail] = useState(null);

  const handleChooseData = (item) => {
    setDetail(item);
    navigate(`${pathname}/${item.id}`);
  };

  return (
    <>
      <PageTitle
        title={lang['newsletters']}
        breadcrumb={[lang['saha_updates'], lang['newsletters']]}
        backgroundImage={'resources/NewsletterMainpic.jpg'}
        backgroundPosition={'center 40%'}
      />
      <PageIntroduction language={language} />
      {!postId ? (
        <BlogList blogType="newsletter" onChooseData={handleChooseData} />
      ) : (
        <BlogDetail data={detail} blogType="newsletter" postId={postId} />
      )}
    </>
  );
};

export default Newsletters;
