/**
 *
 *
 */

import React, { useState } from 'react';
import { getCookie } from 'utils';
import Ko from './InquiryForm.ko';
import En from './InquiryForm.en';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';
import emailjs from '@emailjs/browser';
import { Loading } from 'components';

const {
  intl: { txt: lang },
} = globalObj;

const defaultValue = {
  name: '',
  type: '',
  email: '',
  phone: '',
  company: '',
  content: '',
};

/**
 * [Component] InquiryForm
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie('country');
  const [loadingFlag, setLoading] = useState(false);
  const [data, setData] = useState(defaultValue);

  /* ===== Functions ===== */
  /**
   *
   */
  const onChange = (name, value) => {
    setData((p) => ({
      ...p,
      [name]: value,
    }));
  };

  /**
   *
   */
  const sendEmail = () => {
    setLoading(true);
    const { name, type, email, phone, company, content } = data;
    if (
      name === '' ||
      type === '' ||
      email === '' ||
      phone === '' ||
      company === '' ||
      content === ''
    ) {
      alert('Please enter all details');
      setLoading(false);
      return;
    }

    const params = {
      name,
      type,
      email,
      phone,
      company,
      content,
    };
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_2,
        params,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          alert('Complete!');
          setData(defaultValue);
        },
        (error) => {
          alert('Failed');
          setLoading(false);
          console.log(error.text);
        }
      );
  };

  /* ===== Render ===== */
  return (
    <>
      <Loading visible={loadingFlag} />
      <PageTitle
        title={lang['inquiry_form']}
        breadcrumb={[lang['support'], lang['inquiry_form']]}
        backgroundImage={'resources/support/support_2_1.jpg'}
        backgroundPosition={'center 45%'}
      />
      {language !== 'KR' ? (
        <En onChange={onChange} sendEmail={sendEmail} data={data} />
      ) : (
        <Ko onChange={onChange} sendEmail={sendEmail} data={data} />
      )}
    </>
  );
};

export default PageIndex;
