/**
 *
 *
 */

import React, { useState } from 'react';
import { getCookie } from 'utils';
import { PageTitle } from 'components';
import globalObj from 'locale/intlGlobals';
import { BlogList, BlogDetail } from 'components/BlogView';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] Downloads
 * --
 */
const Downloads = () => {
  /* ===== State ===== */
  const language = getCookie('country');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { postId } = useParams();
  const [detail, setDetail] = useState(null);

  const handleChooseData = (item) => {
    setDetail(item);
    navigate(`${pathname}/${item.id}`);
  };

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang['downloads']}
        breadcrumb={[lang['saha_updates'], lang['downloads']]}
        backgroundImage={'resources/DownloadHubMainpic.jpg'}
        // backgroundPosition={'center 65%'}
      />
      {!postId || postId === null || postId === undefined ? (
        <BlogList
          blogType="download"
          onChooseData={handleChooseData}
          viewType="list"
        />
      ) : (
        <BlogDetail
          data={detail}
          blogType="download"
          viewType="list"
          postId={postId}
        />
      )}
    </>
  );
};

export default Downloads;
