/**
 *
 *
 */

import React from 'react';

/**
 * [Component] PageTitle
 * --
 */
const PageTitle = ({
  title,
  breadcrumb,
  backgroundImage,
  backgroundPosition,
  backgroundSize,
}) => {
  /* ===== State ===== */
  /* ===== Render ===== */
  const length = breadcrumb.length - 1;
  return (
    <>
      <section
        className="page-title text-center"
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundPosition,
          backgroundSize,
        }}
      >
        <div className="container">
          <div className="title-text">
            {/* === Title === */}
            <h1>{title}</h1>
            <ul className="title-menu clearfix">
              <li>
                <a href="/">home &nbsp;/ </a>
              </li>
              {/* === breadcrumb === */}
              {breadcrumb.map((item, index) => (
                <li
                  style={{
                    color: '#fff',
                    fontWeight: index === length ? 'bold' : 'normal',
                  }}
                >
                  {item}
                  {index < length && <> &nbsp;/&nbsp;</>}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

PageTitle.defaultProps = {
  title: 'Page title',
  breadcrumb: ['Subpage'],
  backgroundImage: 'images/background/3.jpg',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
};
export default PageTitle;
