//Homepage

import React from 'react';
// import HeaderTop from 'components/HeaderTop';
// import HeaderUpper from 'components/HeaderUpper';
// import HeaderNav from 'components/HeaderNav';
// import PageWrapper from 'components/PageWrapper';
// import Footer from 'components/Footer';
import { getCookie } from 'utils';
//import { useNavigate } from 'react-router-dom';

import MainSlider from 'components/MainSlider';
import AboutSection from './components/AboutSection';
import ExpoBanner from './components/ExpoBanner';
//import SahaBoard from './components/SahaBoard';
//import Transportation from './components/Transportation';
//import SahaEduPreview from './components/SahaEduPreview';
//import KicProgress from './components/KicProgress';
//import NewslettersPreview from './components/NewslettersPreview';
import ContentPreview from './components/ContentPreview';  // <-- Add this import (replace with the actual path)
import KicIntro from './components/KicIntro';


// import MemberPreview from './components/MemberPreview';
import FAQKo from './components/FAQKo';
import Form from './components/Form';

import SubscribeSection from './components/SubscribeSection';
//import DevelopmentPlanSection from './components/DevelopmentPlanSection';




/**
 * [Component] Home
 * --
 */
const hrStyle = {
  border: 'none',
  borderTop: '1px solid #ccc',
  margin: '1px 0'
};


const Home = () => {
  /* ===== State ===== */
  //const [searchResults, setSearchResults] = useState([]);
  /*const navi = useNavigate();*/
  const language = getCookie('country');



  /* ===== Render ===== */
  return (
    <>
      <MainSlider language={language} />

      {/* Abount */}
      <AboutSection language={language} />
      
      <KicIntro language={language} />
      {/* <MemberPreview language={language} /> */}
      <ExpoBanner />

      <section className="subscribe-section">
        <SubscribeSection />
      </section>

<ContentPreview 
        language={language}
        firebasePath="board/news/datas"
        navigationPath="/saha-district-bulletin"
        titleEn="SAHA Updates"
        titleKr="사하 뉴스"
        descriptionEn="Stay up-to-date with what's happening in the Saha District! Check out our latest news and announcements below."
        descriptionKr="사하구에서 일어나는 일들에 대한 최신 소식을 확인하세요! 아래에서 최신 뉴스와 공지사항을 확인하세요."
      />  <hr style={hrStyle} />

<ContentPreview 
        language={language}
        firebasePath="board/newsletter/datas"
        navigationPath="/newsletters"
        titleEn="SAHA Newsletters"
        titleKr="SAHA 뉴스레터"
        descriptionEn="Get the latest newsletters from the Saha District."
        descriptionKr="사하구에서 발행하는 최신 뉴스레터를 받아보세요."
      />   <hr style={hrStyle} />
<ContentPreview 
        language={language}
        firebasePath="board/kic-progress/datas"
        navigationPath="/kic-progress"
        titleEn="SAHA KICs"
        titleKr="지식산업센터 동향"
        descriptionEn="We will showcase updates and advancements related to the Knowledge Industry Centers (KICs) in the Saha District. It will provide news about ongoing projects, technological advancements, business opportunities, and other initiatives taking place at the KICs."
        descriptionKr="사하구 지식산업센터(KIC)와 관련된 업데이트 및 발전 상황을 소개합니다. 진행 중인 프로젝트, 기술 발전, 비즈니스 기회 및 기타 이니셔티브에 대한 뉴스를 제공합니다."
      /> <hr style={hrStyle} />
<ContentPreview 
        language={language}
        firebasePath="board/transportation/datas"
        navigationPath="/lifestyle-transportation"
        titleEn="Discover SAHA"
        titleKr="라이프스타일 & 교통"
        descriptionEn="Visitors can find information about the local lifestyle, amenities, cultural activities, and the transportation network including buses, trains, and other transit options available in Saha District. It will help both residents and visitors navigate and experience the district to its fullest."
        descriptionKr="방문객들은 사하구에서 이용할 수 있는 버스, 기차 및 기타 교통수단을 포함한 교통망과 지역 라이프스타일, 편의시설, 문화 활동 등에 대한 정보를 찾을 수 있습니다. 주민과 방문객 모두가 사하구를 마음껏 탐색하고 경험할 수 있도록 도와줄 것입니다."
      /> <hr style={hrStyle} />
<ContentPreview 
        language={language}
        firebasePath="board/edu/datas"
        navigationPath="/saha-edu"
        titleEn="SAHA Education"
        titleKr="SAHA 교육"
        descriptionEn="Welcome to Saha EduSphere, your gateway to the vibrant educational landscape of the Saha District. This page delves into our top-tier local schools, unique educational initiatives, and the global educational opportunities available within Korea."
        descriptionKr="여기서는 사하구의 탁월한 학교와 독창적인 교육 프로그램뿐만 아니라, 한국에서 접할 수 있는 다양한 글로벌 교육 기회까지도 만나보실 수 있습니다."
      />
      

      
      {/* <!-- Contact Section --> */}
      <section className="appoinment-section section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <FAQKo />
            </div>
            <div className="col-lg-6">
              <Form />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Section --> */}
    </>
  );
};

export default Home;
