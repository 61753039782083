/**
 *
 *
 */

import React from 'react';

const styles = {
  articleTitle: {
    color: '#333',
    marginBottom: 15,
  },
  articleWarpper: {
    textAlign: 'center',
  },
  articleImage: {
    width: '100%',
    height: '100%',
    maxHeight: 305,
    minHeight: 235,
    margin: '0 auto',
    backgroundSize: 'cover',
    // backgroundSize: 'cover 100%',
    border: '1px solid #dadada',
    borderRadius: 20,
  },
};

/**
 * [Component] Sample
 * --
 */
const Ko = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>선진화된 인프라와 첨단 기술이 결합된 미래 도시의 대안</h2>
                <br />
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <hr style={{ marginTop: 0 }} />
              <h3>전략적 비전과 혁신의 도시 개발 모델</h3>
              <hr />
              <p className="align-justify">
                혁신적인 도시환경과 풍부한 산업 인프라를 구축하여 사하구는
                사람들에게 편안하고 풍요로운 삶의 기회를 제공합니다. 우리는 첨단
                기술을 적극적으로 도입하여 스마트 시티 구축에 주력하고, 미래
                지향적인 산업 분야에 투자하며 창조적인 일자리를 창출합니다.
                또한, 국제적인 교류와 다문화 통합을 촉진하여 다양성과 상호
                이해를 존중하는 사회를 형성합니다. 부산 사하구는 더 나은 미래를
                향한 열린 마음과 선도적인 역할을 수행하는 지역으로 발전하고
                있습니다.
                <br />
                <br />
                목표 달성을 위해, 두 가지 핵심 전략이 진행 중입니다. 첫 번째로,
                사하구 2030개발계획은 사하구를 지속 가능한 성장의 엔진으로
                변화시키는 미래지향적인 로드맵입니다. 이 계획은 고급 기술의 역량
                강화를 통해 사업체와 주민 모두를 위한 번영하는 생태계를
                조성하는데 초점을 맞추고 있습니다.
                <br />
                <br />두 번째 계획인 가덕도 신공항 연계 사하발전 구상은 신공항
                건설과의 맞춤형 연계를 통해 사하구의 가능성을 확장하는 데 초점을
                맞춥니다. 신공항과의 긴밀한 연결을 통해, 사하구는 연결성,
                접근성, 그리고 매력을 강화함으로써 더 많은 국제 방문객과 투자를
                유치할 수 있습니다. 이 두 계획은 모든 주민의 삶의 질을
                향상시키는 동시에, 사하구를 글로벌 산업과 항공 네트워크의 중요한
                노드로 자리매김하는 것을 목표로 하고 있습니다.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section
        className="service-overview section bg-gray"
        style={{
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundImage: `url("resources/WhySaha/2_DevelopmentPlan_pic3.jpg")`,
          opacity: 0.75,
        }}
      >
        <br />
        <br />
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* == */}

            <div className="col-md-12 col-lg-6">
              <hr />
              <h2>
                사하구: 혁신과 첨단 제조산업을 육성하는 스마트 도시로의 전환
              </h2>
              <hr />

              <p className="align-justify">
                신평-장림 산업단지의 재개발은 이러한 변화의 주역으로 부상하고
                있습니다. 이 산업단지는 총 면적 156,703㎡, 총 건평면적
                1,069,040㎡에 이르며, 혁신과 첨단 제조 산업의 활발한 중심지로
                재탄생하고 있습니다. 이 개발 계획은 인프라 강화, 환경 친화적
                산업으로의 전환, 그리고 전용 연구 개발 기능의 설립 등을 포함한
                전략적인 추진을 통해 신평-장림 산업단지의 재개발을 성공적으로
                이끌고 있습니다.
                <br />
                <br />
                사하구는 혁신지원센터 건립, 산업단지 클라우드 서비스 도입,
                그리고 해양바이오클러스터 조성 등의 전략적 이니셔티브를 추진하고
                있습니다. 이러한 발전과 함께 사하구는 글로벌 인재와 기업을
                유치하기 위해 지속적으로 노력하고 있습니다. 또한, 주민들의 생활
                및 근무 환경을 개선하기 위해 예술과 창작을 촉진하는 공간 조성,
                도시 숲 조성, 주거용 주택 조성 등에도 적극적으로 투자하고
                있습니다.
              </p>
            </div>
            <div className="col-md-12 col-lg-6"></div>

            <div className="col-md-12 col-lg-6"></div>
            <div className="col-md-12 col-lg-6">
              <hr />
              <h2>사하구의 전략적 위치 활용</h2>
              <hr />

              <p className="align-justify">
                사하구는 우수한 입지 조건과 강력한 교통망을 바탕으로 상당한
                경쟁력을 보유하고 있습니다. 특히, 사하구는 해양 바이오 산업
                클러스터의 조성을 통해 해양 산업에서 주요한 역할을 수행할 것으로
                기대되고 있습니다.
                <br />
                <br />
                가덕도 신공항의 건립과 이에 따른 교통 개선 사업들은 사하구가
                지역 내 주요 교통 요충지로서의 역할을 강화하는 데 상당한 기여를
                할 것으로 판단됩니다. 특히, 공항과 부산 시내를 신속하게 연결하는
                급행철도, 하단과 녹산을 이어주는 구간, 그리고 하단과 사상을
                연결하는 신설 지하철 노선 등의 계획은 사하구의 교통 인프라를
                더욱 고도화하여 지역 내의 효율적인 이동과 교통 편의성을 제공할
                것으로 예상됩니다. 이러한 사업들은 공공기관에서도 철저한 검토와
                전문적인 추진을 거쳐 사하구의 중요성을 더욱 높여주는 핵심적인
                요소로 인정받고 있습니다.
              </p>
            </div>

            <div className="col-md-12 col-lg-6">
              <hr />
              <h2>글로벌 커뮤니티를 위한 생활 환경 개선</h2>
              <hr />

              <p className="align-justify">
                사하구는 포용적이고 다문화적 커뮤니티의 중요성을 인지하며,
                다양한 인구와 외국인 주민들을 포함한 생활 조건을 풍요롭게
                개선하는 데 적극적으로 노력하고 있습니다. 편안한 주거 환경, 녹색
                도시 공간, 그리고 다채로운 여가 시설의 제공은 세계적인 인재를
                매혹하는 풍부하고 세련된 생활을 약속합니다.
              </p>
            </div>
            <div className="col-md-12 col-lg-6"></div>

            <div className="col-md-12 col-lg-6"></div>
            <div className="col-md-12 col-lg-6">
              <hr />
              <h2>지속 가능한 미래가 우리를 기다리고 있습니다</h2>
              <hr />

              <p className="align-justify">
                사하구는 4차 산업혁명의 선두주자로서 명확한 역할을 수행하고
                있습니다. 사하구는 다양하고 활발한 커뮤니티를 형성하며, 강력한
                경제적 목표와 조화를 이루는 생활 환경을 제공하는 데 주력하고
                있습니다. 함께 번영하며 지속 가능한 미래를 위해 사하구는 자연과
                삶이 조화를 이루는 앙상블 도시를 실현하기 위해 최선을 다하고
                있습니다.
              </p>
            </div>

            {/* == */}
          </div>
        </div>
      </section>

      {/*  */}
      <section
        className="service-overview section "
        style={{
          backgroundPosition: 'center bottom',
          backgroundSize: 'cover',
          backgroundImage: `url("resources/WhySaha/2_DevelopmentPlan_pic4.jpg")`,
          opacity: 0.85,
        }}
      >
        <br />
        <br />
        <br />
        <br />
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-9 col-md-12">
              <div className="content-block">
                <h2>사하구의 미래</h2>
                <br />
              </div>
            </div>

            {/* 카드 */}
            {[
              [
                '부산-울산-경남 메가 시티의  중심: 사하구',
                `대한민국에서 두 번째로 큰 메가시티의 원동력인 사하구는 부산, 울산, 경상남도의 협력에서 중요한 역할을 담당하고 있습니다. 이 파트너십은 인구 천만 명이 넘는 지자체 간 메가시티를 만드는 것을 목표로 합니다. 특히 지식 기반 산업과 첨단 기술에 중점을 두고 도시 산업 환경을 육성합니다. 급성장하는 산업을 지원하기 위해 사하구는 주거, 상업, 비즈니스, 문화 기능을 결합하여 업무, 생활, 문화가 역동적으로 어우러진 공간을 조성하고 있습니다. 사하는 풍부한 관광 자원을 바탕으로 국내외 관광객이 찾는 주요 목적지가 되어 대도시 내 해양 관광 명소로 자리매김하고자 합니다.`,
              ],
              [
                `하늘 연결 도시: 접근성 및 연결성 강화`,
                `가덕도 신공항 개발과 부산광역교통체계 개선으로 사하구는 모든 사람의 접근성과 연결성을 강화하는 것을 목표로 합니다. 대중교통 입체 환승 시스템과 혁신적인 교통 체계 개발에 중점을 둔 사하구는 더욱 편리하고 쾌적한 여행 경험을 약속합니다.`,
              ],
              [
                `활기찬 녹색 도시: 자연과 조화를 이루는 건강한 삶 증진`,
                `사하구는 광범위한 공원 네트워크와 하천 및 해안 수변 공간을 조성하여 시민들에게 건강하고 자연친화적인 생활환경을 제공하기 위해 최선을 다하고 있습니다. 또한 활용도가 낮은 지역과 도시계획시설 내 공개공지를 우선적으로 활용하여 녹지율을 높이고 고요한 휴식 공간을 제공합니다.`,
              ],
              [
                `편안함과 기쁨의 도시: 일상 생활의 질 향상`,
                `사하구는 인구구조와 지리적 특성에 맞는 다양한 편의시설을 제공하여 구민들의 다양한 욕구를 충족시키기 위해 노력하고 있습니다. 도시재생과 생활밀착형 시설 확보를 통해 주민들이 일상을 충분히 즐길 수 있는 고품격 생활환경을 제공하는 것을 목표로 합니다.`,
              ],
              [
                `혁신과 교육의 중심지`,
                `사하구는 혁신과 교육의 중심지라는 자부심을 갖고 있습니다. 사하구는 기술 발전과 창의성에 도움이 되는 환경을 조성하여 선도적인 기술 기업과 스타트업이 이 지역에 뿌리를 내리도록 유도하고 있습니다. 최고 수준의 교육 기관과 함께 차세대 리더, 혁신가를 양성하는 터전을 제공합니다. 사하구는 교육과 산업의 교차점을 장려함으로써 기술 혁신과 학문적 우수성을 위한 선도적인 글로벌 허브가 될 것을 꿈꾸고 있습니다.`,
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                style={{ marginBottom: 18 }}
                key={`askldj${index}`}
              >
                <div
                  className="align-justify"
                  style={{
                    background: '#fff',
                    boxShadow: '1px 1px 15px -1px rgba(0,0,0,0.1)',
                    height: '100%',
                    padding: 25,
                    // borderRadius: 13,
                  }}
                >
                  <h4>{item[0]}</h4>
                  <hr />
                  <p>{item[1]}</p>
                </div>
              </div>
            ))}
            {/* 카드 */}
            {/* 카드 */}
            <div className="col-lg-6 col-md-6" style={{ marginBottom: 18 }}>
              <div
                className="align-justify"
                style={{
                  background: '#fff',
                  boxShadow: '1px 1px 15px -1px rgba(0,0,0,0.1)',
                  height: '100%',
                  padding: 20,
                  // borderRadius: 13,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  color: '#fff',
                  backgroundColor: '#11ab87',
                }}
              >
                <h4>
                  <b>사하구의 새로운 미래를 이끌어줄 리더가 되어주세요</b>
                </h4>
              </div>
            </div>
            {/* 카드 */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ko;
